import axios from 'axios'
import api from '@/config/api'
import firebase from 'firebase/app'
import store from '@/store'
const { BASE } = api

const getIdToken = async () => {
  try {
    const { isSignedIn } = store.getters
    if (isSignedIn) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      axios.defaults.headers.common.Authorization = `Bearer ${idToken}`
    }
  } catch (error) {
    // axios.defaults.headers.common.Authorization = null
  }
}


const apiCall = async ({ url, method, ...args }) => {
  try {
    await getIdToken()
    const API_URL = `${BASE}/${url}`
    return await axios({ method: method || 'get', url: API_URL, ...args })
  } catch (err) {
    throw new Error(err)
  }
}


export default apiCall
