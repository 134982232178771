<template>
  <div>
    <MainHeader />
    <main>
      <Breadcrumb page="Privacy and Policy" />
      <section class="terms">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="content">
                <h3>INTRODUCTION</h3>
                <p>TOTO Connect and TOTO PBX are trading names of Gencom Technologies Ltd.</p>
                <p>We take your personal information seriously and are committed to safeguarding it. To do that, we have process and procedures to ensure that your information is handled responsibly and in accordance with applicable data protection and privacy laws. We thank you for your trust and will act responsible to provide the required protection to ensure safety and privacy.</p>
                <h3>A. ABOUT US AND PURPOSE OF THIS</h3>
                <p>
                  This privacy notice aims to give you information on how we collect and processes your personal data through your use of this website, including any data you may provide through this website when you sign up, respond to campaigns, to newsletter, purchase a product or service or take part in a competition etc.
                </p>
                <p>
                  This website is not intended for children and we consciously do not collect data relating to minors. It is important that you read our privacy notice along with any other privacy notice or fair usage notice we may provide on specific occasions when we collect or processing personal data about you so that you are fully informed of how and why we are using your data.
                </p>
                <p><strong>SOLE CONTROL</strong></p>
                <p>
                  Gencom Technologies Ltd  trading as TOTO Connect  is in controls and responsible for your personal data (collectively referred to as Gencom Telecommunications, “we”, “us” or “our” in this privacy notice).
                </p>
                <p>
                  We have appointed a data privacy manager who manages and oversee queries in relation to privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.
                </p>
                <p><strong>HOW TO CONTACT US</strong></p>
                <p>
                  Full name of legal entity: TOTO Connect trading as Gencom Technologies Ltd<br>
                  Name or Data Manager: The Data Manager<br>
                  Email address: privacy@totoconnect.co.uk  or hello@totoconnect.co.uk<br>
                  Postal address: Devonshire House, Manor Way, Borehamwood, UK, WD6 1QQ
                </p>
                <p>We would, appreciate the chance to deal with your concerns before you escalate to any agencies or bodies to resolve any concerns.</p>
                <p><strong>CHANGES AND DUTY TO INFORM US</strong></p>
                <p>It is mandatory that the personal data we hold is accurate and current for your account with us. Kindly keep us informed if your personal data changes during your association with us.</p>
                <p><strong>OTHER LINKS</strong></p>
                <p>Out website may include links to third-party websites, plug-ins and applications for information and performance purpose.  By clicking on such links or enabling those connections may allow third parties to collect or share data about you for enabling performance or desired services. We do not control these third-party websites and are not responsible for their privacy statements. While  you leave our website, we encourage you to read the privacy notice of every website you visit in the course of a transaction.</p>
                <h3>B. DATA COLLECTED AND YOU</h3>
                <p>Personal data, or personal information relates to information about an individual or User from which that individual can be identified. It does not include data where the identity has been removed to anonymise the party.</p>
                <p>For the purpose of delivering our services you subscribed or registered for we may collect, use, store and transfer different kinds of personal data about you which may be classified and as follows.</p>
                <p>
                  <ul>
                    <li>Usage Profile includes information about how you use our online website, solutions, products, and services.</li>
                    <li>The Identity Data includes Name, Surname, username or similar identifier and title.</li>
                    <li>Contact Data consist of, billing address, email address and telephone numbers.</li>
                    <li>Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
                    <li>Financial information may include bank account and payment card details.</li>
                    <li>Transaction Data includes details about payments to and from you and other details of products and services you have ordered or used from us.</li>
                    <li>Technical Data cover internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices from which you choose to access our portal/ website.</li>
                    <li>Profile Data includes your username and password, purchases or orders made by you, your interests, preferences, feedback, and survey responses.</li>
                    <li>Usage Data includes information about how you use our website, products and services.</li>
                  </ul>
                </p>
                <p>
                  Further we collate information of aggregated Data such as statistical or demographic data for delivering improved service to customer profile and needs. Such data is anonymised and not considered personal data in law as this data does not directly or indirectly reveal your identity. However, we treat the combined data as personal data which will be used in accordance with this privacy notice and utmost care and for the purpose of taking decision to serve you better.
                </p>
                <p>
                  Also, we do not collect any Special Categories of Personal Data about you as well as well do not gather or collect any information about criminal convictions and offences.
                </p>
                <p><strong>MANDATORY TO PROVIDE RIGHT PERSONAL DATA</strong></p>
                <p>
                  In areas we need to collect personal data by law, or under the terms of a contract we sign with you and should you fail to provide such data when requested, we may not be able to perform the contract we have or are trying to be entered with you. Under such cases when identified we will be forced to notify and terminate such services.
                </p>
                <h3>C. COLLATING YOUR PERSONAL INFORMATION</h3>
                <p>
                  We deploy different methods to collect data from and about you from following channels
                </p>
                <p>
                  <ul>
                    <li>Direct Communication. You may provide us details as your Identity, Contact and Financial Information while inputting in forms or by corresponding with us by post, phone, email or other inputs. This includes personal data shared while applying for our products or services or registration</li>
                    <li>Setting up of your account online</li>
                    <li>Subscription to our services, campaigns, publications</li>
                    <li>While requesting marketing collateral to be sent</li>
                    <li>Joining our competitions, promotion or survey; or On giving us feedback</li>
                    <li>Automated technologies or interactions. As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. Please see our cookie policy for further details.</li>
                    <li>Information from Third parties or publicly available sources. We may receive personal data about you from various third parties and public sources as set out below:</li>
                    <li>
                      Source of Technical Data are from the following parties
                      <ol>
                        <li>analytics providers such as Google/Yahoo etc based outside the EU;</li>
                        <li>advertising networks such as Facebook/Instagram/Twitter based outside the EU; and</li>
                        <li>Contact, Financial and Transaction Data from providers of technical, payment and delivery services such as Stripe, Braintree, Paypal.</li>
                        <li>Identity and Contact Data from publicly availably sources such as Companies House and the Electoral Register based inside the EU.</li>
                      </ol>
                    </li>
                  </ul>
                </p>
                <h3>D. USING YOUR PERSONAL DATA</h3>
                <p>
                  We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
                </p>
                <p>
                  <ul>
                    <li>In order to perform a contract, we are about to enter or have entered into with you.</li>
                    <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                    <li>Where we need to comply with a legal or regulatory obligation.</li>
                  </ul>
                </p>
                <p>
                  As a rule, we do not depend on consent as a legal basis for processing your personal data other than in relation to sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by Contacting us.
                </p>
                <p><strong>HOW WE MAY USE YOUR PERSONAL DATA</strong></p>
                <p>
                  The below table capture the scenarios of Objective of use, Information considered and , reason for usage to throw light of circumstances. We have also identified what our legitimate interests are where appropriate.
                </p>
                <p>
                  Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please Contact us if you need details about the specific legal ground should you need any clarification.
                </p>
                <p><strong>Scenario of Data use classified below</strong></p>
                <p class="block">
                  Objective: To register you as a new customer<br>
                  Information used: (a) Identity (b) Contact<br>
                  Reason for usage: Performance of a contract with you
                </p>
                <p class="block">
                  Objective: For processing and delivering your order end to end <br>
                  Information used:(a) Identity (b) Contact (c) Financial (d) Transaction (e) Marketing and Communications <br>
                  Reason for usage:(a) Performance of a contract with you (b) Necessary for our legitimate interests (to recover debts due to us)
                </p>
                <p class="block">
                  Objective: To manage our relationship with you which will include:(a) Notifying you about changes to our terms or privacy policy(b) Asking you to leave a review or take a survey <br>
                  Information used: (a) Identity (b) Contact (c) Profile (d) Marketing and Communications <br>
                  Reason for usage: (a) Performance of a contract with you (b) Necessary to comply with a legal obligation
                  (c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)
                </p>
                <p class="block">
                  Objective: To enable you to partake in a prize draw, competition or complete a survey <br>
                  Information used: (a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and Communications <br>
                  Reason for usage: (a) Performance of a contract with you (b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)
                </p>
                <p class="block">
                  Objective: To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data) <br>
                  Information used: (a) Identity (b) Contact (c) Technical <br>
                  Reason for usage: (a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise) (b) Necessary to comply with a legal obligation
                </p>
                <p class="block">
                  Objective: To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you <br>
                  Information used: (a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and Communications (f) Technical <br>
                  Reason for usage: Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)
                </p>
                <p class="block">
                  Objective: To use data analytics to improve our website, products/services, marketing, customer relationships and experiences <br>
                  Information used: (a) Technical (b) Usage <br>
                  Reason for usage: Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)
                </p>
                <p class="block">
                  Objective: To make suggestions and recommendations to you about goods or services that may be of interest to you <br>
                  Information used: (a) Identity(b) Contact (c) Technical (d) Usage (e) Profile <br>
                  Reason for usage: Necessary for our legitimate interests (to develop our products/services and grow our business)
                </p>
                <p><strong>MARKETING</strong></p>
                <p>
                  We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. We have established the following personal data control mechanisms
                </p>
                <p><strong>PROMOTIONAL OFFERS FROM US</strong></p>
                <p>
                  In the course of business or service with you we may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you and could be in the context of marketing.
                </p>
                <p>
                  You will receive marketing communications from us if you have requested information from us or purchased goods or services from us or if you provided us with your details when you entered a competition or registered for a promotion and, in each case, you have not opted out of receiving that marketing.
                </p>
                <p>
                  <strong>
                    THIRD-PARTY MARKETING
                  </strong>
                </p>
                <p>
                  We will get your express opt-in consent before we share your personal data with any company outside of Gencom Technologies Ltd trading as TOTO Connect for promotion and marketing purposes.
                </p>
                <p>
                  <strong>OPTING OUT
                  </strong>
                </p>
                <p>
                  You can ask us or third parties to stop sending you marketing messages at any time by logging into the website and checking or unchecking relevant boxes to adjust your marketing preferences or by following the opt-out links on any marketing message sent to you or by Contacting us at any time.
                </p>
                <p>
                  Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.
                </p>
                <p>
                  <strong>
                    COOKIES
                  </strong>
                </p>
                <p>
                  You have the option to set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information on cookies we use, please refer to our cookie policy.
                </p>
                <p>
                  <strong>CHANGE OF PURPOSE</strong>
                </p>
                <p>
                  We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please Contact us.
                </p>
                <p>
                  If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
                </p>
                <p>
                  Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
                </p>
                <h3>
                  E. DISCLOSURES OF YOUR PERSONAL DATA
                </h3>
                <p>
                  We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions. We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.
                </p>
                <p>
                  <ul>
                    <li>
                      External Third Parties as set out in the Glossary for the purpose of delivering services
                    </li>
                    <li>
                      Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.
                      We require all third parties to respect the security of your personal data and to treat it in accordance with the law.
                    </li>
                  </ul>
                </p>
                <h3>
                  F. INTERNATIONAL TRANSFERS
                </h3>
                <p>
                  Some of our external third parties are based outside the European Economic Area (EEA) so their processing of your personal data will involve a transfer of data outside the EEA.
                </p>
                <p>
                  Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
                </p>
                <p>
                  We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, see European Commission: Adequacy of the protection of personal data in non-EU countries.
                  Please Contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the EEA.
                </p>
                <h3>
                  G. DATA SECURITY
                </h3>
                <p>
                  We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions, and they are subject to a duty of confidentiality.
                </p>
                <p>
                  We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
                </p>
                <h3>H. DATA RETENTION</h3>
                <p>
                  HOW LONG WILL YOU USE MY PERSONAL DATA FOR?
                </p>
                <p>
                  We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
                </p>
                <p>
                  To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
                </p>
                <p>
                  By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers for [tax] purposes.
                </p>
                <p>
                  In some circumstances you can ask us to delete your data: see Request erasure below for further information.
                </p>
                <p>
                  In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.
                </p>
                <h3>
                  I. YOUR LEGAL RIGHTS
                </h3>
                <p>
                  Under certain circumstances, you have rights under data protection laws in relation to your personal data.
                </p>
                <p>
                  <ul>
                    <li>
                      Request access to your personal data.
                    </li>
                    <li>
                      Request correction of your personal data.
                    </li>
                    <li>
                      Request erasure of your personal data.
                    </li>
                    <li>
                      Object to processing of your personal data.
                    </li>
                    <li>
                      Request restriction of processing your personal data.
                    </li>
                    <li>
                      Request transfer of your personal data.
                    </li>
                    <li>
                      Right to withdraw consent.
                    </li>
                  </ul>
                </p>
                <p>
                  If you wish to exercise any of the rights set out above, please Contact us by emailing data@totoconnect.co.uk
                </p>
                <p>
                  <strong>FEE REQUIREMENT</strong>
                </p>
                <p>
                  You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively, we may refuse to comply with your request in these circumstances.
                </p>
                <p>
                  <strong>
                    IDENTYING YOURSELF AND AUTHORISATION FROM YOU
                  </strong>
                </p>
                <p>
                  We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
                </p>
                <p>
                  <strong>TIMELINE FOR RESPONSE</strong>
                </p>
                <p>
                  Our normal response time is one month. Subjectively, it may take us longer than a month if your request is particularly complex or you have made several requests. In this case, we will notify you and keep you updated.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
export default {
  name: 'Privacy',
  components: {
    MainHeader,
    MainFooter,
    Breadcrumb,
  },
}
</script>
