<template>
  <div>
    <MainHeader />
    <main>
      <section class="checkout mt-5">
        <div
          class="container mt-5"
        >
          <div class="row">
            <div class="col-lg-12 package">
              <header
                id="plan_type"
                class="section-header pb-2"
              >
                <p class="text-left">
                  I would like to go for
                </p>
              </header>
              <div class="box-content">
                <div class="package-list plan">
                  <div class="row">
                    <div
                      v-for="(plan,index) in plans"
                      :key="index"
                      class="col-sm-4"
                    >
                      <b-form-radio
                        v-model="selectedPlan"
                        name="checkout-package"
                        :value="index"
                      >
                        {{ plan.name }}
                        <p class="text-gray">
                          <small>{{ plan.desc }}</small>
                        </p>
                      </b-form-radio>
                    </div>
                  </div>
                </div>
              </div>
              <header
                id="plan_duration"
                class="section-header pb-2"
              >
                <p class="text-left">
                  Bill me
                </p>
              </header>
              <div class="box-content">
                <div class="package-list period">
                  <div class="row">
                    <div
                      v-for="(price,name) in plans[selectedPlan].prices"
                      :key="name"
                      class="col-sm-4"
                    >
                      <b-form-radio
                        v-model="selectedBillingCycle"
                        name="checkout-package-billing-cycle"
                        :value="name"
                      >
                        <div class="left">
                          {{ price.name }}
                        </div>
                        <div class="right">
                          <div>
                            £ {{ price.cost }}
                          </div>
                        </div>
                        <span v-if="price.special"><i class="ri-award-line" />{{ price.special }}</span>
                      </b-form-radio>
                    </div>
                  </div>
                </div>
              </div>
              <header
                id="number_region"
                class="section-header pb-2"
              >
                <p class="text-left">
                  Number Country and Area
                </p>
              </header>
              <div class="row mb-5">
                <div class="col-sm-6 mb-3">
                  <v-select
                    v-model="form.country"
                    label="name"
                    placeholder="Country"
                    :options="countries"
                    disabled
                    :reduce="country => country.id"
                  />
                </div>
                <div class="col-sm-6">
                  <v-select
                    v-model="form.area"
                    label="name"
                    :options="areas"
                    placeholder="Area or Type"
                    :reduce="area => area.localAreaId"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="selectedPlanPrice"
              class="col-lg-12 d-none d-lg-block"
            >
              <header class="section-header pb-2">
                <p class="text-left">
                  Order Summary
                </p>
              </header>
              <div class="order-summary p-4">
                <div class="row mb-2">
                  <div class="col-sm-6">
                    Solo Plan
                  </div>
                  <div class="col-sm-6 text-right">
                    £{{ (selectedPlanPrice * 0.8).toFixed(2) }}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-6">
                    VAT
                  </div>
                  <div class="col-sm-6 text-right">
                    £{{ (selectedPlanPrice * 0.2).toFixed(2) }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <strong>Total</strong>
                  </div>
                  <div class="col-sm-6 text-right">
                    <strong>£{{ (selectedPlanPrice).toFixed(2) }}</strong>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12">
                    <b-button
                      block
                      variant="primary"
                      class="d-inline-flex align-items-center justify-content-center align-self-center"
                      @click.prevent="checkout"
                    >
                      <i class="ri-shield-check-fill mr-2" /> Checkout Securely
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="selectedPlanPrice"
              class="order-summary-mobi d-bloxk d-lg-none"
            >
              <div class="price">
                Total £{{ (selectedPlanPrice).toFixed(2) }}
                <small>Incl VAT £{{ (selectedPlanPrice * 0.2).toFixed(2) }}</small>
              </div>
              <b-button
                block
                variant="primary"
                class="d-inline-flex align-items-center justify-content-center align-self-center mb-2"
                @click.prevent="checkout"
              >
                <i class="ri-shield-check-fill mr-2" /> Checkout Securely
              </b-button>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import vSelect from 'vue-select'
import plans from '@/config/plans'
import apiCall from '@/services/apiCall'

export default {
  name: 'Checkout',
  components: {
    MainHeader,
    vSelect,
  },
  data() {
    return {
      plans,
      countries: [
        {
          id: 1,
          name: 'United Kingdom',
          code: 'GB',
          supplier: 'localDIDs',
          monthlyFee: 1.9900,
          setupFee: 1.9900,
        },
      ],
      areas: [],
      selectedPlan: 1,
      selectedBillingCycle: null,
      form: {
        plan: null,
        country: 1,
        area: null,
      },
    }
  },
  computed: {
    selectedPlanPrice() {
      if (this.selectedPlan !== null && this.selectedBillingCycle !== null) {
        return this.plans[this.selectedPlan].prices[this.selectedBillingCycle].cost
      }
      return null
    },
  },
  watch: {
    selectedPlan(val) {
      this.selectedBillingCycle = 'biennial'
      this.form.plan = this.plans[val].prices.biennial.id
      this.$scrollTo('#plan_duration')
    },
    selectedBillingCycle(val) {
      this.form.plan = this.plans[this.selectedPlan].prices[val].id
      this.$scrollTo('#number_region')
    },
  },
  async mounted() {
    await this.getAreas()
  },
  methods: {
    async getAreas() {
      try {
        const result = await apiCall({ url: 'areas', method: 'get' })
        this.areas = result.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async checkout() {
      try {
        if (this.form.area === null) {
          this.$swal('Please fill all fields',
            'Please select the Region or Area',
            'warning')
          return
        }
        const params = this.form
        const me = this
        this.$chargebee.openCheckout({
          hostedPage() {
            return apiCall({ url: 'checkout', method: 'post', data: params }).then((response) => response.data.data)
          },
          success(hostedPageId) {
            me.$router.push({ name: 'MyAccount' })
          },
          error(error) {
            me.$swal('Opps! something went wrong. Please try again later')
          },
        })
      } catch (error) {
        this.$swal('Opps! something went wrong. Please try again later')
      }
    },
  },
}
</script>
<style>
.vs__dropdown-toggle {
  border: 2px solid #e3e3e6;
  border-radius: 9px;
}
.vs__search {
  padding: 6px !important;
}
.vs__selected {
  font-weight: 700 !important;
  font-size: 17px !important;
  color: rgb(66 43 113) !important;
}
.swal-button {
  border-radius: 3px;
  background-color: #281a43;
}
</style>
