<template>
  <section
    class="testimonials"
  >
    <div
      class="container"
    >
      <header class="section-header">
        <h2>Testimonials</h2>
        <p>What they are saying about us</p>
      </header>
      <swiper
        class="testimonials-slider"
        :options="swiperOptions"
      >
        <swiper-slide>
          <div class="testimonial-item">
            <div class="stars">
              <i class="ri-star-s-fill" />
              <i class="ri-star-s-fill" />
              <i class="ri-star-s-fill" />
              <i class="ri-star-s-fill" />
              <i class="ri-star-s-fill" />
            </div>
            <p>
              when I register with eBay, Gumtree, amazon and others I give my 2nd Mobile number from
              toto to avoid spam calls and NOT revealing my original bank phone numbers to hackers and criminals.
              My nuisance call have dropped by 99%
            </p>
            <div class="profile mt-auto">
              <img
                src="@/assets/img/testimonials.jpg"
                class="testimonial-img"
                alt=""
              >
              <h3>Sara Wilsson</h3>
              <h4>Designer</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="testimonial-item">
            <div class="stars">
              <i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" />
            </div>
            <p>
              I am an agent selling Cars and other second hand goods and wanted to have temporary
              numbers just for the business for limited time and toto made it easy and smart by helping me focus
              on business than the hassle of dealing with unwanted calls through the day.
            </p>
            <div class="profile mt-auto">
              <img
                src="@/assets/img/testimonials.jpg"
                class="testimonial-img"
                alt=""
              >
              <h3>Sara Wilsson</h3>
              <h4>Designer</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="testimonial-item">
            <div class="stars">
              <i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" />
            </div>
            <p>
              As a student I am very active on Social Media and you constantly make and break relationship.
              With my 2nd and 3rd very cheap numbers I keep my personal number secure for family and banking. Period.
            </p>
            <div class="profile mt-auto">
              <img
                src="@/assets/img/testimonials.jpg"
                class="testimonial-img"
                alt=""
              >
              <h3>Sara Wilsson</h3>
              <h4>Designer</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="testimonial-item">
            <div class="stars">
              <i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" />
            </div>
            <p>
              Some of us are vulnerable to online games and betting etc but sadly we are forced
              to give IDs for SMS, OTP validation etc to pass through security.  With my new toto
              I feel my personal banking phone number remains safe and this industry is rife with
              online fraudsters. Feel more safe.
            </p>
            <div class="profile mt-auto">
              <img
                src="@/assets/img/testimonials.jpg"
                class="testimonial-img"
                alt=""
              >
              <h3>Sara Wilsson</h3>
              <h4>Designer</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="testimonial-item">
            <div class="stars">
              <i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" /><i class="ri-star-s-fill" />
            </div>
            <p>
              I am retired and read about Emmeline Hartley's Royal Mail scam and other from HMRC, Holiday scams etc.
              Online has become a mine for criminals. When I found 'toto' which is very affordable, I decided
              to take a new personal number for Banking and HMRC and choose 2nd number for all online
              purchase. Any call to toto need not be taken seriously unless I know them. A great freedom with control.
            </p>
            <div class="profile mt-auto">
              <img
                src="@/assets/img/testimonials.jpg"
                class="testimonial-img"
                alt=""
              >
              <h3>Sara Wilsson</h3>
              <h4>Designer</h4>
            </div>
          </div>
        </swiper-slide>
        <div
          slot="pagination"
          class="swiper-pagination"
        />
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'Testimonials',
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      swiperOptions: {
        speed: 400,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        },
      },
    }
  },
}
</script>

<style>

</style>
