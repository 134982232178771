const plans = [
  {
    name: 'Solo',
    desc: 'The essentials to get you up and running with the Device App.',
    recommended: false,
    prices: {
      monthly: {
        id: 'solo-GBP-Monthly',
        name: 'Monthly',
        cost: 5.99,
        special: null,
      },
      yearly: {
        id: 'solo-GBP-Monthly',
        name: 'Yearly',
        cost: 3.99,
        special: 'Save 10%',
      },
      biennial: {
        id: 'solo-GBP-Monthly',
        name: 'Two Year',
        cost: 1.99,
        special: 'Save 20%',
      },
    },
    icon: 'ri-user-fill',
    iconColor: '#07d5c0',
    featuresDesc: 'Full access plus:',
    availableFeatures: [
      'No set up or activation fees',
      'UK mobile number',
      'Unlimited UK mobile minutes',
      'Unlimited UK landline minutes',
      'Unlimited UK Customisable voicemail',
    ],
    notAvailableFeatures: [
      'International calls',
      'Dedicated Solo Pro email support',
    ],
  },
  {
    name: 'Solo Pro',
    desc: 'For the small business owner looking for more flexibility.',
    recommended: false,
    prices: {
      monthly: {
        id: 'solo-GBP-Monthly',
        name: 'Monthly',
        cost: 8.99,
        special: null
      },
      yearly: {
        id: 'solo-GBP-Monthly',
        name: 'Yearly',
        cost: 5.99,
        special: 'Save 10%',
      },
      biennial: {
        id: 'solo-GBP-Monthly',
        name: 'Two Year',
        cost: 2.99,
        special: 'Save 20%',
      },
    },
    icon: 'ri-user-add-fill',
    iconColor: '#65c600',
    featuresDesc: 'Solo features, plus:',
    availableFeatures: [
      'New UK mobile or landline number Or choose to bring your current UK number',
      '1 free number swap',
      'Unlimited calls to US / Canada and Ireland',
      'Dedicated Solo Pro email support',
    ],
    notAvailableFeatures: [
      'Full call reporting and analytics',
      'CRM Intergrations',
    ],
  },
  {
    name: 'Teams',
    desc: 'Transform your company\'s communications with the #workfromanywhere solution.',
    recommended: true,
    prices: {
      monthly: {
        id: 'solo-GBP-Monthly',
        name: 'Monthly',
        cost: 15.99,
        special: null
      },
      yearly: {
        id: 'solo-GBP-Monthly',
        name: 'Yearly',
        cost: 12.99,
        special: 'Save 10%',
      },
      biennial: {
        id: 'solo-GBP-Monthly',
        name: 'Two Year',
        cost: 10.99,
        special: 'Save 20%',
      },
    },
    icon: 'ri-team-fill',
    iconColor: '#ff901c',
    featuresDesc: 'Solo Pro features, plus:',
    availableFeatures: [
      'Personal account manager',
      'Team management portal',
      'Instant number allocation and deallocation',
      'Call forwarding',
      'Full call reporting and analytics',
      'CRM integrations',
    ],
    notAvailableFeatures: [],
  },
]
export default plans
