<template>
  <div>
    <MainHeader />
    <main>
      <Breadcrumb page="FAQ" />
      <section
        class="faq"
      >
        <div
          class="container"
          data-aos="fade-up"
        >
          <header class="section-header pb-0">
            <h2>F.A.Q</h2>
            <p>Frequently Asked Questions</p>
          </header>

          <div class="row">
            <div class="col-lg-12">
              <div
                class="accordion accordion-flush"
              />
              <fragment
                v-for="(faq,i) in faqs"
                :key="i"
              >
                <h3 class="text-center font-weight-bold m-5">
                  {{ faq.title }}
                </h3>
                <div
                  v-for="(qa,j) in faq.qas"
                  :key="j"
                  class="accordion-item mb-2"
                >
                  <h2
                    v-b-toggle="`faq-${i}-${j}`"
                    class="accordion-header"
                  >
                    <b-button
                      variant="link"
                      class="accordion-button d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <i class="ri-question-answer-line mr-2" />{{ qa.q }}
                    </b-button>
                  </h2>
                  <b-collapse :id="`faq-${i}-${j}`">
                    <div
                      class="accordion-body"
                      v-html="qa.a"
                    />
                  </b-collapse>
                </div>
              </fragment>
            </div>
          </div>
        </div>
      </section>
    </main>
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import faqs from '@/config/faqs'
export default {
  name: 'FAQ',
  components: {
    MainHeader,
    MainFooter,
    Breadcrumb,
  },
  data() {
    return {
      faqs,
    }
  },
}
</script>
