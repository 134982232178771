<template>
  <div>
    <MainHeader />
    <main>
      <Breadcrumb page="About" />
      <section
        id="about"
        class="about"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <div class="content">
                <h3>Who We Are</h3>
                <p class="text-dark">
                  TOTO CONNECT is a service from Gencom Technologies Ltd a Public Electronic Communications Network (“PECN”) operator in the UK with millions of UK numbers allocated by Ofcom. A company with over ten years of successful operations in B2B and B2C sector.
                </p>
                <p class="text-dark">
                  This division focuses on offering affordable smart App based telephony service for small and medium businesses. Our solutions are Mobile and web based meeting the evolving customer habits and agility in working with existing business communication eco-system.  With endeavour to offer you the best of value and service that will give you an edge in the market.
                </p>
              </div>
            </div>

            <div
              class="col-lg-6 d-flex align-items-center"
            >
              <img
                src="@/assets/img/about.jpg"
                class="img-fluid"
                alt=""
              >
            </div>
          </div>
        </div>
      </section>
    </main>
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
export default {
  name: 'About',
  components: {
    MainHeader,
    MainFooter,
    Breadcrumb,
  },
}
</script>
