const faqs = [
  {
    title: 'About service',
    qas: [
      {
        q: 'What is toto connect number?',
        a: '<p>TOTO Connect provides supplementary or additional Mobile numbers that are very low priced to work on your main handset without changing handsets.  It allows you to make and receive calls like normal phone numbers without revealing your original SIM Mobile number.</p><p>This helps in keeping your personal number personal and private. Your toto numbers can be used for business, responding to classifieds, online shopping, social media activities, dating. Yes, securing your privacy from fraudsters, hackers and general public. With flexibility and affordability to change number or cancel with minimum cost.<p>',
      },
      {
        q: 'Can I trust toto-connect for safety?',
        a: '<p>Our services are provided by Gencom Technologies Ltd a decade old company with OFCOM numbers and with established network in providing value services to both Business and Customers.  The company is not a third-party app services company, but a licensed telecom company with established relation with Top Carriers like BT, Vodafone and other Tier 1 Carriers. Pls visit gencomtel.com.<p>',
      },
      {
        q: 'Does toto allow Free Calls?',
        a: '<p>Our prices are one of the best. Our current policy is to give special offers to existing customers.  We are forced to avoid FREE call option to protect hackers and fraudsters from misusing the system.</p>',
      },
      {
        q: 'Does toto need Internet connection?',
        a: '<p>Yes. Toto connect works on Wi-Fi and Data. This is required for authentication through OTP and SMS.  The voice, chat and messaging functions too need very little internet.</p>',
      },
      {
        q: 'What\'s toto Connect and why is it different?',
        a: '<p>This is the easiest way to BUY Mobile numbers that work on Internet without SIMs.  Which is very affordable and quick to buy and activate on the same Mobile where you have your primary Mobile Number.<p><p>Allows to keep your permanent Mobile number private for banking and other confidential services.</p>',
      },
      {
        q: 'What is unique about toto connect?',
        a: '<p>The unique feature is ability to travel around with the same Mobile Number around the world and access and make calls from wherever WiFi and data is available.  Ability to have multiple numbers on same Mobile through toto app to address both business and personal works. Flexible and low cost is another USP.</p>',
      },
      {
        q: 'Can I make calls without internet access?',
        a: '<p>No. This is required to make and receive both voice and sms but it will be very low utilization of data.</p>',
      },
      {
        q: 'Does toto work like normal phones for making calls?',
        a: '<p>Yes. But note that it cannot be used for emergency calls.</p>',
      },
      {
        q: 'Is messaging feature available and is it charged?',
        a: '<p>Messaging between toto-to-toto customers are free through the App. Yes, there is a charge for messaging to other networks and can be found under rates section.</p>',
      },
      {
        q: 'Any hidden fees like connection charges etc?',
        a: '<p>There are no hidden charges and pricing is based on the Plan you choose and rates for all calls and services available under Rates section.</p>',
      },
    ],
  },
  {
    title: 'On using toto-connect',
    qas: [
      {
        q: 'Do I need a permanent Mobile number to buy toto connect Numbers?',
        a: '<p>Yes. The toto number and App need validation with OTP/authentication while setting up. Further it does not have emergency call feature.</p>',
      },
      {
        q: 'What handsets/devices can I use?',
        a: '<p>The handset should be running Android or IOS versions given below; Android- above version 6.0,   IOS – 8.0 and over</p>',
      },
      {
        q: 'Does TOTO Connect work while travelling abroad?',
        a: '<p>It will work on WiFi and data while travelling</p>',
      },
      {
        q: 'Can I use TOTO Connect on other devices?',
        a: '<p>Yes. But you cannot make concurrent calls.  You can use it on tablets like iPad. It can work like a normal phone for making and receiving calls plus messaging.</p>',
      },
      {
        q: 'Do I need to allow Notifications?',
        a: '<p>Yes. In order to allow toto to receive and send messages, the app should be allowed to receive notifications. It can be done by going to Setting and enabling notification under toto app.</p>',
      },
      {
        q: 'Can TOTO Connect be used instead of my SIM card?',
        a: '<p>TOTO cannot be used to replace SIM card.  It piggy back on your original SIM number without revealing you number to third party when making toto calls.</p>',
      },
      {
        q: 'For internet is Wi-Fi or mobile data?',
        a: '<p>It does not matter as your phone should always be picking WiFi as first option to minimise data usage from Mobile.</p>',
      },
    ],
  },
  {
    title: 'TOTO messaging',
    qas: [
      {
        q: 'Is messaging free on toto app?',
        a: '<p>Messaging between toto-to-toto customers is free. However for other networks there is a charge and its very less compared to other providers and can be found under "rates"</p>',
      },
      {
        q: 'Is there advantage in sending SMS from TOTO Connect?',
        a: '<p>TOTO Connect SMS are cheaper than regular SMS, especially for international texts. You can send TOTO Connect SMS to any type of phone, anywhere in the world. You can also send and receive SMS from your additional TOTO Connect numbers especially for online business to keep your privacy.</p>',
      },
    ],
  },
  {
    title: 'Pricing and Processing',
    qas: [
      {
        q: 'How much does TOTO Connect cost for both number and services?',
        a: '<p>Our numbers cost less than a pound a month.  With very cost-effective call and messaging bundles.  Also plans for international calling and the freedom to go with pay as you go at one of the best tariffs to keep spent low. Check Plan and Rates for pricing.</p>',
      },
      {
        q: 'How are calls charged?',
        a: '<p>The calls are charged per minute on calls made and on bundles based on Plans subscribed.</p>',
      },
      {
        q: 'How to add credits on my account?',
        a: '<p>This is easy as you have the option to top up credit as well as change plans by calling customer service or by accessing your account through the web portal.</p>',
      },
      {
        q: 'What payment options are there?',
        a: '<p>On activating your account through the web and accessing your account online you have various payment gateway to choose like Stripe, Braintree, PayPal etc.</p>',
      },
      {
        q: 'Can the operator charge me for using TOTO Connect?',
        a: '<p>No, they cannot charge you for toto-connect.  The Operator will charge you only for your data or internet subscription.</p>',
      },
      {
        q: 'Do you recommend Apple and iTunes payment?',
        a: '<p>Please note that when you make payment through AppStore or iTunes, Apple may charge their fee which is over the standard charges of toto-connect. However, if you access your account through web then there won’t be any additional charges.</p>',
      },
      {
        q: 'How quick is it to activate my TOTO Connect number?',
        a: '<p>This can be done in minutes.  The moment you register your account though our website totoconnect.co.uk or com and download the totoApp and activate you plan you are good to start making and receiving calls.</p>',
      },
      {
        q: 'Where can I download the TOTO Connect App?',
        a: '<p>Depending on your handset you can either go to Google Play or AppStore and download toto connect once you register online with your registered Mobile number.</p>',
      },
      {
        q: 'Is toto-connect available in all countries?',
        a: '<p>Yes, you can download from any country.  The features and numbers available will be limited and subjective to country.  However, services can be enjoyed as long as there is internet connection.</p>',
      },
    ],
  },
]
export default faqs
