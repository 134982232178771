import firebase from 'firebase/app'
import firebaseConfig from '@/config/firebase'
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Fragment from 'vue-fragment'
import VueSwal from 'vue-swal'
import VueScrollTo from 'vue-scrollto'
import App from './App.vue'
import router from './router'
import store from './store'
import '../node_modules/remixicon/fonts/remixicon.css'
import '@/assets/css/style.css'


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Fragment.Plugin)
Vue.use(VueSwal)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 100,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
})
Vue.config.productionTip = false
Vue.prototype.$log = console.log
firebase.initializeApp(firebaseConfig)
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
firebase.auth().languageCode = 'en'

window.Chargebee.init({
  site: 'totoconnect-test',
})
Vue.prototype.$chargebee = window.Chargebee.getInstance()

let app = ''
firebase.auth().onAuthStateChanged((user) => {
  store.commit('onAuthStateChanged', user)
  store.commit('onUserStatusChanged', !!user)
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app')
  }
})
