<template>
  <fixed-header :threshold="0">
    <header
      class="header header-bg"
    >
      <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
        <b-link
          class="logo d-flex align-items-center"
          :to="{ name: 'Home' }"
        >
          <img
            src="@/assets/img/logo_white.png"
            alt="logo"
          >
        </b-link>
        <Navigation />
      </div>
    </header>
  </fixed-header>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import FixedHeader from 'vue-fixed-header'
export default {
  name: 'MainHeader',
  components: {
    Navigation,
    FixedHeader,
  },
}
</script>
<style>
.vue-fixed-header--isFixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
}
</style>
