<template>
  <div>
    <MainHeader />
    <main>
      <Breadcrumb page="Contact Us" />
      <Contact/>
    </main>
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Contact from '@/components/Contact.vue'
export default {
  name: 'ContactUs',
  components: {
    MainHeader,
    MainFooter,
    Breadcrumb,
    Contact,
  },
}
</script>
