<template>
  <div>
    <MainHeader />
    <main>
      <Breadcrumb page="Terms and Conditions" />
      <section class="terms">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="content">
                <p>
                  On Downloading and Registering or using in part or full toto-connect application you are bound by and agree to the Terms and Conditions stated below without exception.
                </p>
                <p>
                  The solution and communication services under these terms and conditions (the "Terms and Conditions") are provided by Gencom Technologies Ltd a company registered in England and Wales with Company number 06931974 and registered office at Devonshire House, Manor Way, Borehamwood, Hertfordshire, England, WD6 1QQ
                </p>
                <p>
                  Within the Terms and Conditions any reference to, "toto-connect", "we", "us", or "our" means and refers to Gencom Technologies Ltd and reference to 'you/yours' refers to YOU; as our User/ Customer.
                </p>
                <p>
                  <strong>TOTO Connect Services</strong>
                </p>
                <p>
                  TOTO Connect service allows the User/You to communicate with others including other persons including toto-connect application Users. Our service feature is not fully free and some services will require credit or payment prior to using them.
                </p>
                <p>
                  <strong>Main tenets</strong>
                </p>
                <p>
                  Our terms and conditions are limited to delivery and use of services through toto connect app and shall not cover your type of devices or network ecosystem for internet connectivity. Your internet connectivity and device are beyond the scope of toto-connect services. You herby fully accept and confirm that toto-connect communication service do not provide the ability and functionality to perform emergency calls.
                </p>
                <p>
                  It is your responsibly to ensure you keep any device on which you use the Service secure so that it cannot be accessed by others who then use your account without your permission. Toto Connect cannot be held responsible for breaches due to personal negligence.
                  Our delivery of Services will be through our network and operator interconnects and suppliers however there can be quality issues that can be due to your location and access to signal footprint at time which is beyond our control.
                </p>
                <p>
                  We notify that our services should not be used for any illegal or improper purposes that is against the law of the jurisdiction your call originates and received.
                </p>
                <p>
                  Further, anyone under 18 is not permitted and considered underage Restricted Services. The service is not suitable for anyone who is of age thirteen and below. This rule precludes should a registration is done a child account under a registered parent.
                </p>
                <p>
                  You hereby consent and recognise that our network provider and us can process your personal data, provided or which you submit to us during the process of registration, purchase, sales, for various requirements like open and manage an account for Services, to deliver products and services ordered by you, for credit checking and fraud prevention, and for product analysis and marketing purposes governed by our ‘Privacy Notice’ in the Terms for Services.
                </p>
                <p>
                  <strong>SERVICES AND TERMS</strong>
                </p>
                <p>
                  <span>1.0 </span> The Agreement
                </p>
                <p>
                  <span>1.1 </span> It is your responsibility to make sure any registered phone number is only used to access Services as permitted in this agreement.
                </p>
                <p>
                  <span>1.2</span> This agreement does not include the supply of Device. Any device used is independent are not related to toto-connect in any way. Any terms relating to Devices will be part of your device supplier's terms and conditions.
                </p>
                <p>
                  <span>2.0</span> The commitment
                </p>
                <p>
                  <span>2.1</span>The Application – You are required to download and use the toto connect Application onto every device on which you intend to use our service.
                </p>
                <p>
                  <span>2.2</span>The Number – Once you open an account, we may offer you one or more toto connect virtual phone number based on your order
                </p>
                <p>
                  <span>3.0</span>The Authorised Use
                </p>
                <p>
                  <span>3.1</span>Subject to monthly fee and Plan purchased and abiding by terms and conditions, toto connect shall offer services for applicable
                  lengths of time, which is restricted to personal, limited, non-transferable, revocable, non-exclusive right and licence to use the agreed set of services.
                </p>
                <p>
                  <span>3.2</span>The agreement does give you rights to:
                </p>
                <p>
                  <span>3.2.1</span>To Extract data, copy, data-mine, cache it, reverse engineer, decompile or de-assemble or otherwise extract information from the Services beyond what is allowed within terms and Conditions.
                </p>
                <p>
                  <span>3.2.2</span>allow to acquire or obtain or claim any ownership of any software or improvements, connected to the Services
                </p>
                <p>
                  <span>3.2.3</span>Further license the use of the Services to any person or parties; and/or
                </p>
                <p>
                  <span>3.2.4</span>create, write or develop any derivative software or any other software based on the Services utilising the proprietary and Confidential Information of toto-connect or company applications.
                </p>
                <p>
                  <span>4.0</span>Our Services
                </p>
                <p>
                  <span>4.1</span> On registering our services and verification and validation of your account then you will be eligible to access our services.
                </p>
                <p>
                  <span>4.2</span> Allowing you to upload and communicate with your own content through our Services. And allowing our us and our network provider a fee/royalty free, perpetual and worldwide licence to store, transmit or otherwise deal with any content you upload on our Services though the period of a association.
                </p>
                <p>
                  <span>4.3</span> Over time we may as required:
                </p>
                <p>
                  <ol type="a">
                    <li>make changes or withdraw some or part of the Services.  This is due to demanding changes in technologies, obsolescence, or for new or different product features, to change content providers or to remove, replace or modify content.  Subject to Clause of Disruption, you can end the agreement if this variation is likely to be of detriment to you as explained in Clause 20; and</li>

                    <li>also determine how Services are presented and delivered to the Device or are otherwise made available to you.  We can change the way they are presented, delivered or otherwise made available to you at any time.</li>

                    <li>from time-to-time Toto-connect may, at its own digression, offer various promotions or trials giving free credit or free minutes. Toto-connect reserves the right to withdraw or amend these promotions or trials at any time without any warning, and furthermore expire any unused free credit or free minutes after 30 days unless otherwise stated in the promotion or trial.</li>
                  </ol>
                </p>
                <p>
                  <span>5.0</span> Toto-connect Numbers
                </p>
                <p>
                  <span>5.1</span> Once you have an account, we may offer you Toto-connect numbers for one or more countries.
                </p>
                <p>
                  <span>5.2</span> You may rent up to four toto-connect Numbers for use on your Toto-connect account.
                </p>
                <p>
                  <span>5.3</span> We may for a limited period of time from 1st December 2015, Toto-connect may allocate you a Toto-connect number free of a rental charge with a new Toto-connect account which you can choose to take up at any time during the free Toto-connect number offer period. However, should you refuse this number, at any time cancel it, convert it to a number which you choose to pay rental for, or should it expire, you will not be eligible for further free toto-connect number. However, you will be able to rent additional toto-connect numbers.
                </p>
                <p>
                  <span>5.4</span> toto-connect charges rental for all toto-connect numbers. The rates for these are available in the toto-connect app and rates look up on website
                </p>
                <p>
                  <span>5.5</span>  Calls received by any Toto-connect US or Canada number will be charged for. The rates for these are available from the Toto-connect app. If you do not have credit in your account you will not receive any calls or voicemails to your US/Canada number. It is free to receive SMS to your Toto-connect US or Canada number
                </p>
                <p>
                  <span>5.6</span> No charge for calls and SMS received by any other Toto-connect number. It is free to receive calls and SMS to any Toto-connect number that is not for the US or Canada.
                </p>
                <p>
                  <span>5.7</span> Calls and SMS sent from any Toto-connect number will be charged for. These charges can be found on the Toto-connect website or in the Toto-connect app.
                </p>
                <p>
                  <span>5.8</span> Managing a free Toto-connect number. Your free Toto-connect number will expire after 30 days unless at least one “event” takes place in that period. An event is when you make or receive at least one answered call, you receive one voicemail, or you send or receive at least one SMS on your free Toto-connect number.
                </p>
                <p>
                  <span>5.8.1</span> After every event on a free Toto-connect number, you have a further 30 days free rental period for that Toto-connect number. Free rental periods are not cumulative
                </p>
                <p>
                  <span>5.8.2</span>  At any time you can choose to convert your free Toto-connect number to a rented Toto-connect number, providing that number is still live on your account. Once the number has been converted you will pay a rental fee for it from then on, and for as long as you keep that number.
                </p>
                <p>
                  <span>5.9</span> Other Toto-connect numbers.
                </p>
                <p>
                  <span>5.9.1</span> Rental for Toto-connect numbers must be paid in advance. In order to pay for a new rental period, you must have sufficient funds in your account to cover the amount of the rental and, whilst logged into your account you must follow the link to confirm that you wish to purchase a new rental period. It is your responsibility to ensure that you do this on time, otherwise the number may expire and be removed from your account.
                </p>
                <p>
                  <span>5.9.2</span>   For any Toto-connect number where at least one month’s rental has been paid, there will be a grace period of up to 15 days after the paid rental period ends, during which your virtual number will not be removed from your account and the number will continue to work normally. When you renew the rental for this number however, the rental will be backdated to the date on which the previous rental period expired.
                </p>
                <p>
                  <span>5.10</span>       Porting toto-connect Numbers in and out of the Service.
                </p>
                <p>
                  <span>5.10.1</span>   You can port your Toto-connect UK number out of our service.
                </p><p>
                  to port a UK number out contact support@toto-connect.com. <br>
                  We may charge you a fee £2-00 when porting a number out of our service. If we do, we make this clear on the relevant instruction pages on our website.
                </p>
                <p>
                  <span>5.10.2</span> You can port an existing UK mobile phone number which you own into the Toto-connect service. To port a UK number in contact support@toto-connect.com. We may charge you a fee of £1-00 when porting a number into our service. If we do we make this clear on the relevant instruction pages on our website.
                </p>
                <p>
                  <span>6.0</span> Toto-connect Peer to Peer Services
                </p>
                <p>
                  <span>6.1</span> Over the Internet, you can make and receive free peer to peer calls, and send and receive free messages and/or files . To do this you and the person to whom you are sending have live toto-connect accounts and both be connected to the Internet through Wifi or mobile data. You do not need a credit balance on your Toto-connect account to use the peer-to-peer service.
                </p>
                <p>
                  <span>6.2</span> The peer-to-peer service will operate from your main SIM number only, the profile created with your SIM number. This number must also be your registered Username. You cannot use peer to peer services with Toto-connect numbers.
                </p>
                <p>
                  <span>6.3 </span>You agree that toto-connect may impose limits on the use of the Peer to Peer service in order to ensure “fair use” and to protect the Toto-connect Service and networks, in particular if it appears to Toto-connect that the Toto-connect Service or networks are being resold or otherwise misused, including through their use or access using any software of system not distributed or authorised by Toto-connect.
                </p>
                <p>
                  <span>7.0</span> Disruption to Services
                </p>
                <p>
                  <span>7.1</span>There may be situations when Services are not continuously available or the quality is affected and so we cannot guarantee continuous fault-free service. For instance:
                </p>
                <p>
                  <ol type="a">
                    <li>when we or our network provider need to perform upgrading, maintenance or other work on the network or Services; and</li>

                    <li>because of various factors outside our control, such as poor internet connectivity causing jitter packet loss and poor voice quality, the features or functionality of your Device, regulatory requirements, lack of capacity, interruptions to services from other suppliers, faults in other communication networks, the weather or radio interference caused by obstructions like hills, tunnels or other physical obstructions.</li>
                  </ol>
                </p>
                <p>
                  <span>8.0</span>  What you will do in return Set up and verify your account.
                </p>
                <p>
                  <span>8.1</span>  To set up a Toto-connect account you must follow the verification procideure. If you fail to do so we will not open an account for you.
                </p>
                <p>
                  Secure your Password and Devices.
                </p>
                <p>
                  <span>8.2</span> You must keep your Toto-connect portal password secure and confidential. You are also responsible for the security of your Device and must ensure that you keep it secure (please refer to the Device manufacturer’s user guide for details of how to keep your Device secure).
                </p>
                <p>
                  <span>8.3</span> You should immediately change your password if you become aware that someone is accessing Services on your account without your permission.
                </p>
                <p>
                  <span>8.4</span>You can log into Toto-connect via your Facebook account and are reminded you should also keep these details secure as, if you accept this option you can be logged into Toto-connect whenever you are logged into Facebook.
                </p>
                <p>
                  <span>8.5</span> To the maximum extent permitted by applicable law, you are fully responsible for all activities that occur in relation to the use of the Services, including but not limited to the use of your Toto-connect Account.
                </p>
                <p>
                  Responsible use of Services
                </p>
                <p>
                  <span>8.6</span> In respect of your use of the Service, you shall:
                </p>
                <p>
                  <span>8.6.1</span> to ensure that you or your representatives, provide true, accurate, current and complete information about yourself as prompted by the customer registration form;
                </p>
                <p>
                  <span>8.6.2</span> to maintain and update this information to keep it true, accurate, current and complete. Information about a user shall be referred to as “User Data“. If any information provided by you is untrue, inaccurate, not current or incomplete, Toto-connect has the right to suspend or terminate your account immediately and refuse to provide you with any and all current or future use of any Toto-connect Service
                </p>
                <p>
                  <span>8.7 </span>You may only use Services:
                  <ol type="a">
                    <li>If you are over 13 years of age. This service is not suitable for anyone younger than this.</li>

                    <li>for your own personal use. This means you must not resell or commercially exploit any of the Services or content and you must not impersonate or create a false identity of any person, including but not limited to a Toto-connect Official, forum leader, guide or host.</li>
                  </ol>
                </p>
                <p>
                  <span>8.8</span> You must not use Services or phone number or allow anyone else to use Services or phone number for illegal or improper uses. For example:
                  <ol type="a">
                    <li>for fraudulent, criminal or other illegal activity;</li>

                    <li>in any way which breaches another person’s rights, including copyright or other intellectual property rights;</li>

                    <li>to copy, store, modify, publish or distribute Services or content (including ringtones), except where we give you permission;</li>

                    <li>to download, send or upload content of an excessive size, quantity, or frequency. We will contact you if your use is excessive;</li>

                    <li>in any way which breaches any security or other safeguards or in any other way which harms or interferes with our network, the networks or systems of others or Services;</li>

                    <li>to falsify or delete any author attributions, legal or other proper notices or proprietary designation or labels of the origin or source of software or other content contained in a file that you upload; and</li>

                    <li>to use or provide to others any directory or details about customers.</li>
                  </ol>
                </p>
                <p>
                  <span>8.9</span> You must always co-operate with us and follow our reasonable instructions to ensure the proper use and security of the Services and your account and inform support@toto-connect.com immediately if you believe that the security of your account has been breached. You must only use Devices authorised by us for Connection to our network and also comply with all relevant legislation relating to their use.
                </p>
                <p>
                  Responsible use of Messaging and Storage Services
                </p>
                <p>
                  <span>8.10 </span> While using the Messaging Services, you must not send or upload:
                  <ol type="a">
                    <li>anything that is copyright protected, unless you have permission;</li>

                    <li>unsolicited bulk or commercial communications or other unauthorised communications, or knowingly send any viruses; or</li>

                    <li>anything that is obscene, offensive, abusive, defamatory, menacing, harassing, threatening or is unlawful in any other way.</li>
                  </ol>
                </p>
                <p>
                  <span>8.11</span> We may put limits on the use of certain Services, such as Messaging Services or Storage Services. For example, we may limit the size of messages or storage space.
                </p>
                <p>
                  Responsible use of Age Restricted Services
                </p>
                <p>
                  <span>8.12</span> If you are 18 or over and you access the Age Restricted Services, you must not show or send content from the Age Restricted Services to anyone under 18. You must also ensure that you have deactivated any access to Age Restricted Services if you let anyone under 18 use your Device. Those under 18 are reminded that they should not access any Age Restricted Services.
                </p>
                <p>
                  Responsible use of Services outside the UK
                </p>
                <p>
                  <span>8.13</span>        If you use Services from a country outside the UK, your use of the Services may be subject to laws and regulations that apply in that other country. We are not liable for your failure to comply with those laws or regulations.
                </p>
                <p>
                  <span>9.0</span>      Emergency Service Calls
                </p>
                <p>
                  <span>9.1</span>        YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT EMERGENCY CALLS ARE NOT INTENDED TO BE CARRIED/SUPPORTED BY TOTO-CONNECT SOFTWARE OR SERVICES AND CANNOT BE GUARANTEED AND THAT NEITHER TOTO-CONNECT NOR ANY OF OUR AFFILIATES, SUBSIDIARIES, PARENT COMPANIES, AGENTS, NETWORK SERVICE PROVIDERS, PARTNERS, OR EMPLOYEES ARE OR WILL BE LIABLE FOR THE INABILITY TO MAKE SUCH CALLS. ADDITIONALLY, YOU EXPRESSLY ACKNOWLEDGE THAT TOTO-CONNECT SOFTWARE AND/OR SERVICES WILL CEASE TO FUNCTION DURING A POWER FAILURE AND/OR DISRUPTION TO YOUR INTERNET CONNECTION AND YOU WILL NOT BE ABLE TO USE TOTO-CONNECT SOFTWARE NOR SERVICES TO DIAL ANY PHONE NUMBER INCLUDING EMERGENCY SERVICES.
                </p>
                <p>
                  <span>9.2 </span>       IT IS YOUR RESPONSIBILITY TO MAINTAIN A CONVENTIONAL FIXED LINE TELEPHONY SUBSCRIPTION TO ASSURE THAT YOU HAVE CONTINUAL ACCESS TO EMERGENCY SERVICES.
                </p>
                <p>
                  <span>10.0 </span>     Our rights – Intellectual Property
                </p>
                <p>
                  All rights, including copyright in Services and their content, belong to us, our network provider, or our licensed source, such as a content provider. We and they reserve all our and their rights.
                </p>
                <p>
                  <span>11.0</span>      Your Toto-connect Account
                </p>
                <p>
                  <span>11.1</span>        We will charge you our fees for using some of our Services (the “Charges”). These Charges are specified in our price lists on the Toto-connect website or in the Toto-connect app or otherwise notified to you, when you use our Services (the “Price Lists”).
                </p>
                <p>
                  <span>11.2</span>        You undertake to be solely responsible for:
                </p>
                <p>
                  <span>11.2.1</span>     any and all charges incurred due to your use of any access number, local or otherwise;
                </p>
                <p>
                  <span>11.2.2</span>     any and all charges incurred by your use of any device in connection with the Toto-connect Services or otherwise including, but not limited to, a cellular or mobile phone or tablet and
                </p>
                <p>
                  <span>11.2.3</span>     all billing transactions. Billing transactions include sign-up transactions (purchases at the time of sign-up) and purchase transactions (purchases which are made after sign-up). You may pay for all such transactions via a credit card and/or debit card and other payment methods as on the purchasing pages of our Website. You can also purchase credit from the iTunes website.
                </p>
                <p>
                  <span>11.3</span>        All purchases made in a currency other than US Dollars will be converted to US Dollars at an exchange rate to be determined by Toto-connect. Toto-connect accounts are held in US Dollars, regardless of purchase currency and any display of your Toto-connect credit in a currency other than US Dollars is an approximation only. An accurate accounting of your Toto-connect credit may be obtained at any time by choosing US Dollars when selecting the currency to display on your Toto-connect dashboard.
                </p>
                <p>
                  <span>11.4 </span>       SMS billing will be deducted from your Toto-connect credit at the nominated rate per SMS. SMS are charged based on the size of your message. Message size is determined by the signalling protocol in use, which may vary depending on the input language. More information on message size can be found by contacting support@toto-connect.com.
                </p>
                <p>
                  <span>11.5</span>        You will be charged in 60 second blocks for any connected calls made using a Toto-connect Service, unless we state otherwise in writing. Charges per call or SMS are rounded up to two decimal points of one US Dollar.
                </p>
                <p>
                  <span>11.6</span>        You are solely responsible for any roaming charges incurred as a result of using any services, including, but not limited to Toto-connect Services, which you will be responsible to pay separately to your third party suppliers. This is particularly relevant to some of our Services such as the Toto-connect Callback service. If you are, without limitation, downloading or updating a Toto-connect Application or accessing Toto-connect Services, you may be charged your standard mobile operator’s internet rates for the time you are connected to the internet through your mobile device. Please be aware that internet charges could be high particularly while you are roaming. You will be solely responsible for any mobile operator charges (e.g. internet charges incurred while roaming) or any other additional charges.
                </p>
                <p>
                  <span>11.7 </span>       The credit in your Toto-connect account will expire after a 12-month period unless you:
                  <ol type="a">
                    <li>top up your account with Service credit or</li>

                    <li>you have an active Toto-connect number which has made or received a call or voicemail or sent or received an SMS or</li>

                    <li>
                      you have made chargeable calls or sent chargeable SMS from your Toto-connect number.
                      This means that the actual expiration date will always be 12 months from the date of the most recent successful recharge or use of the Toto-connect service as described above.
                    </li>
                  </ol>
                </p>
                <p>
                  <span>11.8</span>       You acknowledge and agree that:
                </p>
                <p>
                  <span>11.8.1</span>     ANY AND ALL TERMS HEREIN, INCLUDING WITHOUT LIMITATION, CALLING RATES OR PLANS, CAN BE CHANGED OR OTHERWISE MODIFIED BY TOTO-CONNECT AT ANY TIME IN ACCORDANCE WITH THESE TERMS AND CONDITIONS;
                </p>
                <p>
                  <span>11.8.2</span>     ALL SALES ARE FINAL. Unless otherwise stated in these Terms and Conditions, Toto-connect will not issue a refund of any purchase except where required by law;
                </p>
                <p>
                  <span>11.9</span>        When using any available non geographic numbers including “Freephone” numbers (if any), you will be charged in accordance with our Price List on our website. You acknowledge and agree that the rates applicable to these numbers may be higher than the rates applicable to geographic numbers.
                </p>
                <p>
                  <span>11.10</span>        Payments may be subjected to extra levels of verification before they can be captured by Toto-connect. The aim is to protect customer accounts and security. This means that your payment can be slightly delayed before being processed by our payment moderation team. A notification will be sent to you once your payment has been processed and the funds added to your account.
                </p>
                <p>
                  <span>12.0 </span>     Transfer credit to a friend
                </p>
                <p>
                  <span>12.1</span>        Subject to these Terms and Conditions and to Toto-connect instructions, a Toto-connect customer (the “Sender Toto-connect Customer“) shall be entitled to transfer credit to another Toto-connect customer (the “Recipient Toto-connect Customer“) provided that:
                </p>
                <p>
                  <span>12.1.1</span>     The account of the Sender Toto-connect Customer must have enough available credit balance;
                </p>
                <p>
                  <span>12.1.2</span>     The Sender Toto-connect Customer can only transfer up to the total of the remaining balance in his/her Toto-connect account;
                </p>
                <p>
                  <span>12.1.3</span>     The Recipient Toto-connect Customer must be in the Sender Toto-connect Customers contact list;
                </p>
                <p>
                  <span>12.1.4</span>     If the Sender Toto-connect Customer account is put on hold for security reasons, the account of the Recipient Toto-connect Customer will also be put on hold temporarily and the credits received will be unavailable to the Recipient Toto-connect Customer until the account of Sender Toto-connect Customer is re-activated;
                </p>
                <p>
                  <span>12.1.5</span>     The amount transferred to the Recipient Toto-connect Customer is not refundable back to the Sender Toto-connect Customer’s account by Toto-connect ;
                </p>
                <p>
                  <span>12.1.6</span>     The Sender Toto-connect Customer cannot transfer promotional credit
                </p>
                <p>
                  <span>12.1.7</span>     The ‘Transfer Credit’ feature is only available from your Toto-connect account online or as otherwise stated in writing by Toto-connect
                </p>
                <p>
                  <span>12.1.8</span>     The Sender must make total payments to Toto-connect exceeding £10 (or equivalent in local currency) before being allowed to transfer Toto-connect credit to another Toto-connect user.
                </p>
                <p>
                  <span>13.0 </span>     Suspension or Termination of your Services
                </p>
                <p>
                  <span>13.1 </span>       We may Suspend and/or Terminate any or all of the Services you use without notice if:
                  <ol type="a">
                    <li>we reasonably believe you have provided us with false or misleading details about yourself as set out in Clause 8.4;</li>

                    <li>we advise you that your excessive use of Services is causing problems for other users, and you are continuing to use Services excessively;</li>

                    <li>we believe your Device has been lost or stolen;</li>

                    <li>we reasonably believe that you have used Services or a phone number for illegal or improper purposes in contravention of our responsible use requirements in Clause 8 above;</li>

                    <li>we receive a serious complaint against you which we believe to be genuine (for example, if we receive a complaint that you are using Services in any of the ways prohibited in Clauses 8.6 and 8.10);</li>

                    <li>we are required to suspend your Services by the emergency services or other government authorities; or</li>

                    <li>we reasonably believe you have permitted your Device to be unlocked via any unauthorised manner;</li>

                    <li>if we believe you have in any way breached any terms of these Terms and Conditions, any relevant Toto-connect End User License Agreement or any relevant third party End User License Agreement or our privacy statement.</li>
                  </ol>
                </p>
                <p>
                  <span>13.2</span>        We may in our sole discretion report you to any local, state or federal authority, body or agency if you breach any of these Terms and Conditions, any relevant Toto-connect End User License Agreement or our Privacy Statement. We may also, at our sole discretion cooperate with any affected third party by providing information and/or data in either civil or criminal proceedings.
                </p>
                <p>
                  <span>14.0</span>      Liability
                </p>
                <p>
                  <span>14.1</span>        All of our obligations to you relating to Services are set out in this agreement. If you wish to make any variations to this agreement or rely on any other term, you must obtain our agreement to the variation or term in writing.
                </p>
                <p>
                  <span>14.2</span>        To the maximum extent permitted by applicable law, Toto-connect shall not be liable for cost of procurement of substitute goods and services or resulting from any Material, goods or services purchased or obtained or messages received or transactions entered into through or from the use of Toto-connect Services or resulting from unauthorised access to or alteration of your transmissions or data, including but not limited to, damages for loss of profits, use, data or other intangible, even if Toto-connect has been advised of the possibility of such damages. You further agree that, to the maximum extent permitted by applicable law, Toto-connect shall not be liable for any damages arising from interruption, suspension or termination of Toto-connect Services caused by third parties or for causes beyond Toto-connect’s control, including but not limited to direct, indirect, incidental, special, consequential or exemplary damages, whether such interruption, suspension or termination was justified or not, negligent or intentional, inadvertent or advertent.
                </p>
                <p>
                  <span>14.3</span>        Except as set out in 14.4:
                  <ol type="a">
                    <li>other terms, conditions and warranties relating to Services are excluded;</li>

                    <li>and our network provider’s entire liability to you for something we do or don’t do will be limited to £3,000 for one claim or a series of related claims; and</li>

                    <li>we and our network provider are not liable for any loss of income, business or profits, or for any loss or corruption of data in connection with the use of Services. We and our network operator are not liable for any loss or damage that was not reasonably foreseeable when you entered into the agreement.</li>

                    <li>we and our network provider are not liable for loss of the use of money or anticipated savings, loss of information, loss of opportunity, goodwill or reputation, cost of procurement of substitute goods or services or any indirect, special or consequential loss or damage of any kind howsoever arising and whether caused by tort (including negligence), breach of contract or otherwise or, whether any such losses could be reasonably foreseen by us or not or if even if Toto-connect has been advised of the possibility of such damages.</li>
                  </ol>
                </p>
                <p>
                  <span>14.4 </span>       We are under no obligation to edit, review or modify the Material or any third party content. However we reserve the right to remove any Customer information or third party content at any time and without notice.
                </p>
                <p>
                  <span>14.5</span>        We exclude all liability of any kind in respect of:
                </p>
                <p>
                  <span>14.5.1</span>     the accuracy, completeness or suitability for any purpose of any Materials; and
                </p>
                <p>
                  <span>14.5.2</span>     the acts or omissions of other providers of telecommunications or Internet services or for faults in or failures of their equipment.
                </p>
                <p>
                  <span>14.6</span>        We disclaim, without limitation, all liability for identity theft or any other misuse of your identity or information.
                </p>
                <p>
                  <span>14.7 </span>      You acknowledge that our directors, members of staff, sub-contractors, licensors and suppliers shall have the benefit of the limits and exclusions of liability set out in this clause, including in terms of the Contracts (Rights of Third Parties) Act 1999.  Nothing in this Terms and Conditions shall exclude or limit liability for fraudulent misrepresentation.
                </p>
                <p>
                  <span>14.8 </span>       To the maximum extent permitted by applicable law, Toto-connect expressly disclaims any and/or all liability for inbound mobile phone roaming charges, mobile data charges and/or other like or unlike third party charges related to the use of any Toto-connect service from a mobile phone, or any other device or computer while it is roaming or accessing a premium rate service.
                </p>
                <p>
                  <span>14.9 </span>       Toto-connect will not be liable to you in any way for failures, defects or delays in the provision of the Toto-connect Service, or which are caused for any of the following reasons:
                </p>
                <p>
                  <span>14.9.1</span>     you have provided incorrect information when registering with Toto-connect Services;
                </p>
                <p>
                  <span>14.9.2</span>     your mobile device is not able to receive the Toto-connect Services; or
                </p>
                <p>
                  <span>14.9.3</span>     your mobile device is out of range or for some reason cannot be contacted
                </p>
                <p>
                  <span>14.10</span>        You are at all times under a duty to mitigate any losses suffered by you.
                </p>
                <p>
                  <span>14.11</span>        Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages (including losses set out in Clause 16), in such States or jurisdictions, Toto-connect (its licensors) or Toto-connect affiliates’ liabilities shall be limited to the maximum state permitted by law. Toto-connect does not endorse, warrant or guarantee any third party product or service offered through Toto-connect and will not be a party to or in any way be responsible for monitoring any transaction between you and third party providers of products or services.
                </p>
                <p>
                  <span>14.12</span>        Nothing in this agreement removes or limits our liability for fraud, for death or personal injury caused by our negligence or for any liability which can’t be limited or excluded by applicable law. If you are a consumer, the terms of this agreement will not affect any of your statutory rights which you have, which cannot be excluded by this agreement. For more information on your statutory rights, contact your local authority Trading Standards Department or Citizen’s Advice Bureau.
                </p>
                <p>
                  Services – Area where we have no responsibility
                </p>
                <p>
                  <span>14.13</span>   We will try to ensure the accuracy, quality and timely delivery of Services. However:
<ol type="a">
                  <li>we and our network providers accept no responsibility for any use of, or reliance on, Services or their content, or for any disruptions to, or any failures or delays in, Services. This includes, without limitation, any alert Services or virus detection Services; and</li>

                  <li>subject to Clause 8.3 we and our network providers do not make any representations as to the accuracy, comprehensiveness, completeness, quality, currency, error-free nature, compatibility, security or fitness for purpose of Services or their content. They are provided to you on an ‘as is’ basis; and</li>

                  <li>we and our network providers are not providing you with advice of any kind (including without limitation investment or medical advice). Where Services contain investment information, we do not make invitations or offer inducements to enter into any investment agreements.</li>
</ol>
                </p>
                <p>
                  <span>14.14</span>  We and our network providers will not be liable:
<ol type="a">
                  <li>for any loss you may incur as a result of someone using your password, with, or without, your knowledge; or</li>

                  <li>if we or they cannot carry out our duties, or provide Services, because of something beyond our control.</li>
</ol>
                  Others’ content and services – Areas where we have no responsibility.
                </p>
                <p>
                  <span>14.15</span>        You may be able to use Services:
<ol type="a">
                  <li>to upload, email or transmit content using Services; and</li>

                  <li>to access content which is branded or provided by others and to acquire goods and services from others. Where we provide you with such access, all we do is transmit the content to you and we do not prepare or exercise control over the content, goods or services. We and our network providers are not responsible or liable in any way for, and do not endorse, any of this content, goods or services.</li>
</ol>
                </p>
                <p>
                  <span>14.16</span>        This Clause 14 will apply even after this agreement has ended.
                </p>
                <p>
                  <span>15.0 </span>     Privacy Notice and Your Information
                </p>
                <p>
                  <span>15.1</span>        We may pass and share your personal information to our network provider, other communications service providers and network service providers for the detection and prevention of theft and fraud, and to carry out any activities or disclosures to comply with any regulatory, government or legal requirement.
                </p>
                <p>
                  <span>15.2</span>        If you use Services from a country outside the UK it may be necessary to transfer your information to that country. If that country is outside of the EEA, the treatment of your personal information may be subject to laws and regulations applying in that country and which may not protect your information to the same standards applying in the UK and the EEA.
                </p>
                <p>
                  <span>15.3</span>        You must keep any passwords or logins relating to your account and the Services safe and secure. You must not share them with anyone else. If you find or suspect that anyone else knows your passwords or login’s, or can guess them, you must contact us immediately and ask us to change them. This is your responsibility.
                </p>
                <p>
                  <span>15.4</span>        We may contact you before, during and after the Term in order to administer, evaluate, develop and maintain the Toto-connect Services.
                </p>
                <p>
                  <span>15.5 </span>       Toto-connect operates in accordance with the Data Protection Act 1998 and in accordance with the ‘Privacy Policy’ available at our Website (the “Privacy Policy”). You are also required to comply with all data protection legislation.
                </p>
                <p>
                  <span>15.6</span>        By registering for the Services you consent to us using and/or disclosing your personal information for the following purposes:
                </p>
                <p>
                  <span>15.6.1</span>     Processing your application (which, where applicable, may involve credit checking by a licensed credit reference agency who may record that a credit check has been made;
                </p>
                <p>
                  <span>15.6.2</span>     Providing or arranging for third parties to provide customer care/help desk facilities and billing you for the Toto-connect Services (which may involve disclosing your information to third parties solely for those purposes);
                </p>
                <p>
                  <span>15.6.3</span>     To maintain quality and for training purposes, Toto-connect may monitor and record telephone conversations with you;
                </p>
                <p>
                  <span>15.6.4 </span>    To inform you about other Toto-connect product or services unless you opted out to this during the application process or you notify our customer services in writing at support@toto-connect.com signifying that you do not wish to receive this information from us.
                </p>
                <p>
                  <span>15.6.5 </span>    To disclose all or part of your personal data to a regulator (i.e. Ofcom, the Information Commissioner Office), a court, or to a public body to comply with any regulatory, government or legal requirement’; and
                </p>
                <p>
                  <span>15.6.6</span>     To communicate information that describes the habits or usage patterns and/or demographics of the whole or a part of Toto-connect’s customer base (including you) but which is anonymous and does not describe or reveal the identity of any particular customer to any third party.
                </p>
                <p>
                  <span>15.7</span>        Where required Toto-connect shall be entitled to make your name, address and telephone number available to the emergency services.
                </p>
                <p>
                  <span>15.8 </span>       Toto-connect will not sell your data to any third party.
                </p>
                <p>
                  <span>16.0</span>      Disclaimer of Warranties
                  The applicable laws in some jurisdictions may not allow the limitation or exclusion of liability in contracts with consumers and therefore all or part of Clauses 16 and 17 may not apply to you. For instance, if you are a consumer, the terms of these Terms and Conditions will not affect any of the statutory rights which you have, which cannot be excluded by these Terms and Conditions.
                </p>
                <p>
                  <span>16.1 </span>       You affirm that use of Services is at your own risk.
                </p>
                <p>
                  <span>16.2</span>        Toto-connect Services are provided on an “as is” and “AS AVAILABLE” basis.
                </p>
                <p>
                  <span>16.3</span>        To the maximum extent permitted by applicable law, Toto-connect expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, satisfactory quality, fitness for a particular purpose or ability to achieve a particular result and non-infringement. Toto-connect makes no warranty that Toto-connect Services will meet your requirements, that the Services will be uninterrupted, timely, secure, or error free; nor does Toto-connect make any warranty as to the results that may be obtained from the use of Toto-connect Services or as to the accuracy or reliability of any information obtained through the use of Toto-connect Services or that defects in Toto-connect Services will be corrected. Neither Toto-connect nor any of Toto-connect information providers imply any warranties concerning the accuracy, correctness or reliability of the messages received or requested via the use of Toto-connect Services or any of the information, material, services or content contained therein (the “Material“).
                </p>
                <p>
                  <span>16.4 </span>       In the absence of fraud, no oral or written information or advice given by Toto-connect or its agents or licensees shall create a warranty or give rise to any other liability other than is given in these Terms and Conditions.
                </p>
                <p>
                  <span>16.5</span>        You affirm that we have no control over any Material and/or data downloaded or otherwise obtained through your use of Toto-connect Services and that this content is used at your own discretion and risk. You acknowledge and agree that you will be solely responsible for any damage to your computer system or loss of data that results from the download of such Material and/or data.
                </p>
                <p>
                  <span>16.6</span>      Toto-connect makes no warranty and does not guarantee the accuracy or completeness of, and to the maximum extent permitted by applicable law shall not be liable for, any Material displayed, purchased or obtained through or from Toto-connect Services or any transaction entered into through the service. To the maximum extent permitted by applicable law, Toto-connect shall not be liable for any delays, omissions or errors in the Material and shall not be liable for any reliance thereon. In the absence of fraud, no advice or information, whether oral or written, obtained by you from Toto-connect, its members of staff, agents or sub-contractors or through or from the use of Toto-connect Services shall create any warranty not expressly stated herein. You acknowledge that you will not rely on any Material when making any business decision (or when taking any other decision). You acknowledge and affirm that your use of the Material is at your sole risk.
                </p>
                <p>
                  <span>16.7</span>        You acknowledge that there may be additional conditions displayed online relating to particular Material, which you agree to accept before accessing the relevant Material.
                </p>
                <p>
                  <span>17.0</span>      Indemnity
                </p>
                <p>
                  <span>17.1</span>        You shall indemnify in full and hold Toto-connect, its affiliates, subsidiaries, officers, agents, partners, employees and licensors harmless from and against:
                </p>
                <p>
                  <span>17.1.1</span>    any and all claims, actions and proceedings made or brought by any third party,
                </p>
                <p>
                  <span>17.1.2</span>     all liabilities, losses, costs, damages (including legal costs as part of a pre-litigation and settlement process or otherwise), and expenses incurred by Toto-connect,
                </p>
                <p>
                  <span>17.1.3</span>     because of or arising out of your use of Toto-connect Services, your connection to Toto-connect Services, your breach of these Terms and Conditions, or your breach of any right of a third party.
                </p>
                <p>
                  <span>18.0 </span>     Proprietary Rights
                </p>
                <p>
                  <span>18.1</span>        You acknowledge and agree that content, including but not limited to text, software, music, sound, photographs, graphics, video, or other Material of Toto-connect Services or that is contained in sponsor advertisements or information presented to you through Toto-connect Services or advertisers is protected by copyrights, trademarks, service marks, patents, or other proprietary rights and laws. You acknowledge and agree that you are permitted to use this Material only as expressly authorized by Toto-connect or the respective advertisers, as applicable and may not copy, reproduce, transmit, distribute, or create derivative works of such content or information without express written authorization.
                </p>
                <p>
                  <span>18.2</span>        You expressly agree that all intellectual property rights in Toto-connect Services, including but not limited to our Website, our Toto-connect apps any and/or all underlying systems, support platforms, ideas, business models and/or any other like or unlike items associated with Toto-connect shall inure to the sole benefit of Toto-connect. You further agree that you shall not reverse engineer, decompile and/or otherwise attempt to learn the underlying ideas, algorithms and/or any other such proprietary ideas, concepts, content and/or property nor shall you cause or permit any other Party to do same.

                  Notifications sent to Customers
                </p>
                <p>
                  <span>18.3</span>        Any notice given to you by us shall be sent by post, via mobile messaging or electronic mail, through our Website or by displaying notices to you via Toto-connect Services. This may include a notification on your Toto-connect account dashboard or a general notification on our Website.
                </p>
                <p>
                  <span>18.4</span>  You must send notices to us by post or personal service or email sent to: <br>
                  POSTAL ADDRESS: Devonshire House, Manor Way, Borehamwood, Hertfordshire, England, WD6 1QQ <br>
                  EMAIL ADDRESS: support@toto-connect.com
                </p>
                <p>
                  <span>19.0</span> Complaints <br>
                  We make every effort to ensure that our customers are happy with the level of service they receive from us. However, despite our best efforts, things can go wrong. We take customer complaints very seriously and aim to resolve them quickly and efficiently. If you have a complaint about any part of our service, please contact our customer service team at support@toto-connect.com or refer to our complaints code of practice posted on the Toto-connect website. You can also request a copy from our customer service team.
                </p>
                <p>
                  <span>20.0</span>   Modification of these Terms and Conditions.
                </p>
                <p>
                  <span>20.1</span> In the event that any variation Toto-connect makes to these Terms and Conditions is to your material detriment, Toto-connect will give you at least 30 days’ notice in accordance with Clause 19. The change will not take effect until the end of the 30-day notice period. You may, within 14 days of our notice, give Toto-connect not less than 14 days’ notice that if the amendment is not withdrawn you will terminate these Terms and Conditions. In order to ensure continuity, time will be of the essence for your notice to be received by us and if we have not received your notice within that time, you will be bound by the terms of these Terms and Conditions as varied.
                </p>
                <p>
                  <span>20.2</span>        Subject to the Clause 20.1 above, we shall be entitled to notify you of any updates and/or variations of the terms of these Terms and Conditions and our charges, by email, through a notice on Toto-connect invoices or by posting our updated Terms and Conditions on our Website.
                </p>
                <p>
                  <span>21.0</span>      Other terms
                </p>
                <p>
                  <span>21.1</span>        This agreement is governed by English law unless you live in Scotland in which case, it will be governed by Scots Law. Each of us agrees to only bring legal actions about this agreement in a UK court.
                </p>
                <p>
                  <span>21.2</span>        If you, or we or our network provider, delay, or do not take action to enforce our respective rights under this agreement, this does not stop you, or us or them, from taking action later.
                </p>
                <p>
                  <span>21.3</span>        If any of the terms in this agreement are not valid or legally enforceable, the other terms will not be affected. We may replace any item that is not legally effective with a similar term that is.
                </p>
                <p>
                  <span>21.4</span>        We may assign or transfer our rights and obligations under your agreement to a party who agrees to continue complying with our obligations under this agreement, provided that your rights under the agreement or any guarantees given by us to you are not affected. No other person (other than our assignee, if any) may benefit from this agreement.
                </p>
                <p>
                  <span>21.5</span>        In exceptional circumstances, a government authority may order the reallocation or change of phone numbers, in which case we may have to change your phone number for Services.
                </p>
                <p>
                  <span>21.6</span>        You confirm that you have full contractual capacity to agree to the agreement.
                </p>
                <p>
                  Third Party Rights.
                </p>
                <p>
                  <span>21.7</span>        This agreement is entered into by us for the benefit of us and our network provider.
                </p>
                <p>
                  <span>21.8</span>        For the purposes of the Contracts (Rights of Third Parties) Act 1999 it is intended that our network provider will have the right to enforce any rights conferred on it under this agreement and to that extent our network provider will have the same rights against you as would be available if they were a party to this agreement.
                </p>
                <p>
                  Glossary for Terms of Services
                  Additional Services: additional or supplemental services for which a charge is made in addition to the fixed periodic charges for the Services (if applicable).
                </p>
                <p>
                  Age Restricted Services: any Services for use only by customers 18 or over.
                </p>
                <p>
                  Cancellation Fee: means, a fee charged if we end the agreement due to your conduct or if you end your agreement within the Minimum Term. This fee may cover (without limitation) your fixed periodic Charges for the Minimum Term, our administrative costs, costs incurred by us in Connecting and Disconnecting the Services and our payments to operators, network providers, stores or agents.
                </p>
                <p>
                  Charges: charges for access to, and use of, Services. These charges may cover (without limitation) fixed periodic charges, usage charges, account administration fees, fees for Connection and re-Connection, a Cancellation Fee (where applicable) and any costs incurred in collecting outstanding payments from you.
                </p>
                <p>
                  Connection: the procedure by which we give you access to Services. ‘Connected’, ‘Connecting’, and ‘re-Connection’ have corresponding meanings.
                </p>
                <p>
                  Damage: any accidental, sudden and unforeseen damage to the Device caused by external means which affects the operational functioning of the Device.
                </p>
                <p>
                  Disconnection: the procedure by which we stop your access to Services. ‘Disconnected’ and ‘Disconnecting’ have corresponding meanings.
                </p>
                <p>
                  Device: the device or mobile device that is authorised by us for Connection to the network which is used to access Services.
                </p>
                <p>
                  Event: A Toto-connect number does any of the following – makes or receives an answered call, receives a voicemail, or sends or receives an SMS.
                </p>
                <p>
                  Expire (Toto-connect number): when a Toto-connect number is removed from an account and cannot be used any more.
                </p>
                <p>
                  Messaging Services: any email, fax and voicemail Services, text message and multimedia messaging Services, personal information management and other message or communication facilities which let you communicate with others.
                </p>
                <p>
                  MSISDN: the number used for routing calls or SMS to a subscriber.
                </p>
                <p>
                  Network Provider: our third party Toto-connect Number supplier(s).
                </p>
                <p>
                  Peer to Peer: “calls” or “messages” sent over a live Wi-Fi or mobile data internet connection from one Toto-connect user to another, providing they are both logged into their accounts.
                </p>
                <p>
                  Premium Services: any Services which are charged at premium rates – such as international roaming.
                </p>
                <p>
                  Services: any of the following services we offer you, including call services, Messaging Services, Storage Services, Age Restricted Services and Premium Services, which we agree to provide for you.
                </p>
                <p>
                  Storage Services: any Services which offer you storage capacity on the network for storage of content which you access from us.
                </p>
                <p>
                  Suspension: the procedure by which we temporarily Disconnect your access to the Services. ‘Suspend’ has a corresponding meaning.
                </p>
                <p>
                  Third Party Supplier(s): any supplier who is offering any product, service, store, website, softwear and/or other technology owned and controlled by third parties which may incorporated into, accessible through or used to access the Toto-connect Service or into which the Toto-connect service may be incorporated.
                </p>
                <p>
                  Toto-connect Application: application program interface consisting of the sets of routines utilized by the Toto-connect Services to provide the functionality of a given platform or operating system, the Toto-connect Application being included in or linked to the Toto-connect Services.
                </p>
                <p>
                  Toto-connect Number: a MSISDN or DDI (direct dial in) number which supports calls and SMS which is not tethered to a SIM card.
                </p>
                <p>
                  Toto-connect SIM Number: the SIM phone number of your phone. This number must be the user name of your Toto-connect account.
                </p>
                <p>
                  Toto-connect Service: the facilities and the products provided by Toto-connect under this Agreement including without limitation the software distributed by Toto-connect for internet and/or mobile communications, the Toto-connect Paid Services, the Toto-connect Application, user interface and Documentation as well as any future programming fixes, updates and upgrades.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
export default {
  name: 'Terms',
  components: {
    MainHeader,
    MainFooter,
    Breadcrumb,
  },
}
</script>
