<template>
  <div>
    <MainHeader />
    <main>
      <Breadcrumb page="Getting Start" />
      <section
        class="features"
      >
        <div
          class="container"
        >
          <div class="row">
            <div class="col-lg-6">
              <div id="firebaseui-auth-container" />
            </div>
            <div class="col-lg-6 text-center text-lg-right">
              <img
                src="@/assets/img/mobile.png"
                style="max-width:60%"
                class="img-fluid"
                alt=""
              >
            </div>
          </div>
        </div>
      </section>
    </main>
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import * as firebaseui from 'firebaseui'
import '@/assets/css/firebaseui.css'
import firebase from 'firebase/app'


export default {
  name: 'GetStart',
  components: {
    MainHeader,
    MainFooter,
    Breadcrumb,
  },
  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance()
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth())
    }
    ui.start('#firebaseui-auth-container', {
      signInOptions: [
        {
          signInSuccessUrl: '/my-numbers',
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          recaptchaParameters: {
            size: 'invisible',
          },
          defaultCountry: 'GB',
          whitelistedCountries: ['GB'],
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => {
          if (this.$route.query.redirect && this.$route.query.redirect === 'checkout') {
            this.$router.push({ name: 'Checkout' })
          } else {
            this.$router.push({ name: 'MyAccount' })
          }
        },
      },
    })
  },
  methods: {
    submitNumber() {
      this.number = false
      this.otp = true
    },
    submitOtp() {
      this.otp = false
    },
  },
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
