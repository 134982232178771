<template>
  <section class="contact">
    <div class="container">
      <header class="section-header">
        <p>Contact</p>
      </header>

      <div class="row gy-4">
        <div class="col-lg-6">
          <div class="row gy-4">
            <div class="col-md-6 mt-2">
              <div class="info-box">
                <b-icon icon="geo-alt" />
                <h3>Address</h3>
                <p>
                  Devonshire House, Manor Way, <br> Borehamwood, WD6 1QQ <br>
                </p>
              </div>
            </div>
            <div class="col-md-6 mt-2">
              <div class="info-box">
                <b-icon icon="telephone" />
                <h3>Call Us</h3>
                <p>
                  +1 5589 55488 55<br>
                  +1 6678 254445 41
                </p>
              </div>
            </div>
            <div class="col-md-6 mt-2">
              <div class="info-box">
                <b-icon icon="envelope" />
                <h3>Email Us</h3>
                <p>
                  hello@totoconnect.co.uk<br>
                  support@totoconnect.co.uk
                </p>
              </div>
            </div>
            <div class="col-md-6 mt-2">
              <div class="info-box">
                <b-icon icon="clock" />
                <h3>Open Hours</h3>
                <p>
                  Monday - Friday<br>
                  9:00AM - 05:00PM
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 ">
          <b-overlay
            :show="loading"
            rounded="sm"
          >
            <b-form
              method="post"
              class="php-email-form"
              @submit.stop.prevent="submitContact"
            >
              <div class="row gy-4">
                <div class="col-md-6 mt-2">
                  <b-form-group
                    id="contact-input-name"
                    label-for="contact-input-name-field"
                  >
                    <b-form-input
                      id="contact-input-name-field"
                      v-model.trim="$v.form.name.$model"
                      :state="validateState('name')"
                      name="contact-input-name-field"
                      placeholder="Your Name"
                    />
                    <b-form-invalid-feedback
                      id="contact-input-name-feedback"
                    >
                      This is a required field.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="col-md-6 mt-2">
                  <b-form-group
                    id="contact-input-email"
                    label-for="contact-input-email-field"
                  >
                    <b-form-input
                      id="contact-input-email-field"
                      v-model.trim="$v.form.email.$model"
                      type="email"
                      :state="validateState('email')"
                      name="contact-input-email-field"
                      placeholder="Your Email"
                    />
                    <b-form-invalid-feedback
                      id="contact-input-email-feedback"
                    >
                      This is a required field.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="col-md-12 mt-2">
                  <b-form-group
                    id="contact-input-subject"
                    label-for="contact-input-subject-field"
                  >
                    <b-form-input
                      id="contact-input-subject-field"
                      v-model.trim="$v.form.subject.$model"
                      :state="validateState('subject')"
                      name="contact-input-subject-field"
                      placeholder="Subject"
                    />
                    <b-form-invalid-feedback
                      id="contact-input-subject-feedback"
                    >
                      This is a required field.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>

                <div class="col-md-12 mt-2">
                  <b-form-group
                    id="contact-input-message"
                    label-for="contact-input-message-field"
                  >
                    <b-textarea
                      id="contact-input-message-field"
                      v-model.trim="$v.form.message.$model"
                      :state="validateState('message')"
                      name="contact-input-message-field"
                      placeholder="Message"
                    />
                    <b-form-invalid-feedback
                      id="contact-input-message-feedback"
                    >
                      This is a required field.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-md-12 text-center mt-2">
                  <div class="loading">
                    Loading
                  </div>
                  <div class="error-message" />
                  <div class="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                  <vue-recaptcha
                    ref="invisibleRecaptcha"
                    :sitekey="SITE_KEY"
                    :load-recaptcha-script="true"
                    @expired="onExpired"
                    @verify="onVerify"
                  >
                    <b-button
                      id="contact-form-submit"
                      variant="primary"
                      type="submit"
                    >
                      Send Message
                    </b-button>
                  </vue-recaptcha>
                </div>
              </div>
            </b-form>
          </b-overlay>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import apiCall from '@/services/apiCall'
const SITE_KEY = process.env.VUE_APP_CAPTCHA_SITE_KEY

export default {
  name: 'Contact',
  components: { VueRecaptcha },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      SITE_KEY,
      form: {
        'name': null,
        'email': null,
        'subject': null,
        'message': null,
        'g-recaptcha-response': null,
      },
    }
  },
  mounted() {
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required, email,
      },
      subject: {
        required,
      },
      message: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    resetForm() {
      this.form = {
        'name': null,
        'email': null,
        'subject': null,
        'message': null,
        'g-recaptcha-response': null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    submitContact() {
      this.$refs.invisibleRecaptcha.execute()
    },
    async onVerify(response) {
      try {
        console.log('submiting')
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form['g-recaptcha-response'] = response
        this.loading = true
        await apiCall({ url: 'contact-form', method: 'post', data: this.form })
        this.loading = false
        this.$swal('Thank you',
          'We have received your message and we will get back to you soon!',
          'success')
        this.resetForm()
      } catch (error) {
        this.loading = false
        this.$swal('Opps! something went wrong.',
          'Please try again later',
          'warning')
      }
    },
    onExpired() {
      this.$refs.invisibleRecaptcha.reset()
    },
  },
}
</script>
