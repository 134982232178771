<template>
  <section
    class="pricing border-bottom"
  >
    <div
      class="container"
    >
      <header class="section-header">
        <p>Our Plans</p>
      </header>
      <div class="row mb-4">
        <div class="toggle_radio">
          <fragment
            v-for="planType in planTypes"
            :key="planType.key"
          >
            <input
              :id="planType.key"
              v-model="selectedPlanType"
              type="radio"
              class="toggle_option"
              name="toggle_option"
              :value="planType.key"
            >
            <label :for="planType.key">{{ planType.label }} <span v-if="planType.special">{{planType.special}}</span> </label>
          </fragment>
          <div class="toggle_option_slider">
            {{ selectedPlanTypeLabel }}
          </div>
        </div>
      </div>
      <div
        class="row"
      >
        <div
          v-for="(plan,index) in plans"
          :key="index"
          class="col-lg-3 col-md-6"
        >
          <div
            class="box"
            :class="{recommended : plan.recommended}"
          >
            <span
              v-if="plan.recommended"
              class="featured"
            >Recommended</span>
            <i
              :style="{color: plan.iconColor}"
              :class="`${plan.icon} ri-2x`"
            />
            <h3
              :style="{color: plan.iconColor}"
            >
              {{ plan.name }}
            </h3>
            <div class="price">
              £{{ plan.prices[selectedPlanType].cost }}
              <p>Per user, per month, inc VAT</p>
            </div>
            <div class="price-desc">
              {{ plan.desc }}
            </div>
            <b-link
              class="btn-buy my-5"
              :class="{ 'btn-buy-spl':plan.recommended}"
              :to="{ name: 'Checkout' }"
            >
              Buy Now
            </b-link>
            <div class="price-desc text-left mb-1">
              {{ plan.featuresDesc }}
            </div>
            <ul>
              <li
                v-for="(availableFeatures,i) in plan.availableFeatures"
                :key="`available_${i}`"
                class="d-flex align-items-top"
              >
                <i class="ri-checkbox-circle-fill mr-1" />{{ availableFeatures }}
              </li>
              <li
                v-for="(notAvailableFeatures,i) in plan.notAvailableFeatures"
                :key="`not_available_${i}`"
                class="na d-flex align-items-top"
              >
                <i class="ri-close-circle-fill mr-1" />{{ notAvailableFeatures }}
              </li>
            </ul>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-6"
        >
          <div class="box">
            <i
              style="color: #ff0071;"
              class="ri-building-fill ri-2x"
            />
            <h3 style="color: #ff0071;">
              Enterprice
            </h3>
            <div class="price">
              Call us
              <p>50 Numbers Minimum</p>
            </div>
            <div class="price-desc">
              Advanced, customisable solutions for mid-sized and large businesses.
            </div>
            <b-link
              class="btn-buy my-5"
              :to="{ name: 'Checkout' }"
            >
              Get Quote
            </b-link>
            <div class="price-desc text-left mb-1">
              Teams features, plus:
            </div>
            <ul>
              <li class="d-flex align-items-top">
                <i class="ri-checkbox-circle-fill mr-1" />Call recording
              </li>
              <li class="d-flex align-items-top">
                <i class="ri-checkbox-circle-fill mr-1" />Custom Intergrations
              </li>
              <li class="d-flex align-items-top">
                <i class="ri-checkbox-circle-fill mr-1" />International numbers
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import plans from '@/config/plans'
export default {
  name: 'Pricing',
  data() {
    return {
      selectedPlanType: 'monthly',
      plans,
      planTypes: [{
        key: 'monthly',
        special: null,
        label: 'Monthly',
      },
      {
        key: 'yearly',
        special: 'Save 20%',
        label: 'Yearly',
      },
      {
        key: 'biennial',
        special: 'Save 30%',
        label: '24 Months',
      },
      ],
    }
  },
  computed: {
    selectedPlanTypeLabel() {
      const sp = this.selectedPlanType
      return this.planTypes.find((i) => i.key === sp).label
    },
  },
}
</script>
