<template>
  <section class="breadcrumbs">
    <div class="container">
      <ol>
        <li>
          <b-link :to="{ name: 'Home' }">
            Home
          </b-link>
        </li>
        <li>{{ page }}</li>
      </ol>
      <h2>{{ pageTitle || page }}</h2>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    page: {
      type: String,
      required: true,
    },
    pageTitle: {
      type: String,
      default: null,
      required: false,
    },
  },
}
</script>
