const firebaseConfig = {
  apiKey: 'AIzaSyCmew8_XiQvpkD4iZNO3D-6Yrcj_lg_7_Q',
  authDomain: 'totoconnect-74b33.firebaseapp.com',
  projectId: 'totoconnect',
  storageBucket: 'totoconnect.appspot.com',
  messagingSenderId: '967982954075',
  appId: '1:967982954075:web:248ccd6bb78923fddcf17e',
  measurementId: 'G-K49F3PVYNG',
}
export default firebaseConfig
