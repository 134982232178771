<template>
  <nav
    class="navbar"
    :class="{'navbar-mobile' : mobileMenu }"
  >
    <ul>
      <b-nav-item :to="{ name: 'Home' }">
        Home
      </b-nav-item>
      <b-nav-item :to="{ name: 'About' }">
        About
      </b-nav-item>
      <b-nav-item :to="{ name: 'Plans' }">
        Plans
      </b-nav-item>
      <b-nav-item :to="{ name: 'ContactUs' }">
        Contact
      </b-nav-item>
      <li
        v-if="!$store.getters.isSignedIn"
      >
        <b-link
          class="getstarted"
          :to="{ name: 'Login' }"
        >
          Login
        </b-link>
      </li>
      <li>
        <b-link
          v-if="$store.getters.isSignedIn"
          class="getstarted"
          :to="{ name: 'MyAccount' }"
        >
          My Account
        </b-link>
        <b-link
          v-else
          class="getstarted"
          :to="{ name: 'Checkout' }"
        >
          Buy Now
        </b-link>
      </li>
    </ul>
    <b-icon
      icon="list"
      class="mobile-nav-toggle"
      @click.prevent="toogleMobileMenu"
    />
  </nav>
</template>
<script>
export default {
  name: 'Navigation',
  data() {
    return {
      mobileMenu: false,
    }
  },
  methods: {
    toogleMobileMenu() {
      this.mobileMenu = !this.mobileMenu
    },
  },
}
</script>
