<template>
  <div>
    <MainHeader />
    <main>
      <Breadcrumb
        page="MyAccount"
        :page-title="user.displayName || 'MyAccount'"
      />
      <section
        class="about pt-0"
      >
        <div class="container">
          <div class="row mb-4 mt-4">
            <div class="col-md-auto">
              <b-button
                class="d-flex align-items-top justify-content-center mr-3"
                variant="primary"
                @click.prevent="$router.push({ name: 'Checkout' })"
              >
                <i class="ri-phone-line mr-2" />Buy Number
              </b-button>
            </div>
            <div class="col-md-auto">
              <b-dropdown
                html="Add Credit"
                variant="primary"
                class="mr-3"
              >
                <b-dropdown-item-button>
                  £2
                </b-dropdown-item-button>
                <b-dropdown-item-button>
                  £5
                </b-dropdown-item-button>
                <b-dropdown-item-button>
                  £10
                </b-dropdown-item-button>
              </b-dropdown>
            </div>
            <div class="col-md-auto">
              <b-button
                class="d-flex align-items-top justify-content-center mr-3"
                variant="primary"
                @click.prevent="manage"
              >
                <i class="ri-calendar-check-line mr-2" />Manage Subsciptions
              </b-button>
            </div>
            <div class="col-md-auto">
              <b-button
                class="d-flex align-items-top justify-content-center"
                variant="primary"
                @click.prevent="logout"
              >
                <i class="ri-logout-circle-r-line mr-2" />Logout
              </b-button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <b-overlay
                :show="loading"
                rounded="sm"
              >
                <vue-good-table
                  :line-numbers="true"
                  :columns="columns"
                  :rows="subscriptions"
                  :sort-options="{
                    enabled: false,
                  }"
                />
              </b-overlay>
            </div>
          </div>
        </div>
      </section>
    </main>
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import firebase from 'firebase/app'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import apiCall from '@/services/apiCall'
import { mapState } from 'vuex'

export default {
  name: 'MyAccount',
  components: {
    MainHeader,
    MainFooter,
    Breadcrumb,
    VueGoodTable,
  },
  data() {
    return {
      loading: true,
      subscriptions: [],
      cbPortal: null,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Plan',
          field: 'subscription_items',
          formatFn: (i) => {
            return i[0].item_price_id
          },
        },
        {
          label: 'Cost',
          field: 'mrr',
          formatFn: (i) => {
            return `£ ${(i / 100).toFixed(2)}`
          },
        },
        {
          label: 'Number',
          field: 'cf_did_number',
        },
        {
          label: 'Created On',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 't',
          dateOutputFormat: 'do-MMM-Y',
        },
        {
          label: 'Next Renewal',
          field: 'next_billing_at',
          type: 'date',
          dateInputFormat: 't',
          dateOutputFormat: 'do-MMM-Y',
        },
        {
          label: 'Status',
          field: 'status',
          formatFn: (i) => {
            return i.charAt(0).toUpperCase() + i.slice(1)
          },
        },
      ],
    }
  },
  computed: mapState(['status', 'user']),
  mounted() {
    try {
      this.getSubscriptions()
      this.$chargebee.setPortalSession(() => {
        return apiCall({ url: 'manage', method: 'get' }).then((response) => response.data.data)
      })
      this.cbPortal = this.$chargebee.createChargebeePortal()
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    logout() {
      const me = this
      firebase.auth().signOut().then(() => {
        me.$chargebee.logout()
        me.$nextTick(() => {
          this.$router.push({ name: 'GetStart' })
        })
      })
    },
    async getSubscriptions() {
      try {
        this.loading = true
        const result = await apiCall({ url: 'subscriptions', method: 'get' })
        this.loading = false
        this.subscriptions = result.data.data
      } catch (error) {
        this.loading = false
        this.subscriptions = []
        console.log(error)
      }
    },
    async manage() {
      try {
        this.cbPortal.open({
          close() {
            console.log('close clicked')
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
