<template>
  <div>
    <MainHeader />
    <section class="hero d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 d-flex flex-column justify-content-center">
            <h1>
              UK & International Phone Numbers at the Low Price with no hidden cost.
            </h1>
            <h3>
              With toto Mobile App you can add a new number to your office and personal use withing seconds. The Numbers work through a Mobile App and it comes with NO Contract
            </h3>
            <div>
              <div class="text-left text-lg-start">
                <router-link
                  :to="{ name: 'Checkout' }"
                  class="btn-get-started d-inline-flex align-items-center justify-content-center align-self-center"
                >
                  <span>Buy Now</span>
                  <b-icon icon="arrow-right" />
                </router-link>
              </div>
            </div>
          </div>
          <div
            class="col-lg-4 hero-img mt-5"
          >
            <img
              src="@/assets/img/mobile.png"
              class="img-fluid"
              alt="phone"
            >
          </div>
        </div>
      </div>
    </section>
    <section
      class="values pb-2"
    >
      <div
        class="container"
      >
        <header class="section-header">
          <p>Powerfull Features</p>
        </header>

        <div class="row">
          <div class="col-lg-3">
            <div
              class="box"
            >
              <img
                src="@/assets/img/features/moving-number.svg"
                class="img-fluid mb-3"
                alt="moving-number"
              >
              <h3>Moving Numbers and Making Calls</h3>
              <p>With our virtual numbers you have the freedom to manage multiple number on single phone with agility to receive and make calls to manage different stake holder with our smart and friendly tools</p>
            </div>
          </div>

          <div class="col-lg-3 mt-4 mt-lg-0">
            <div
              class="box"
            >
              <img
                src="@/assets/img/features/no-missedcall.svg"
                class="img-fluid mb-3"
                alt="no-missed-call"
              >
              <h3>No Missing Calls with Call Forwarding</h3>
              <p>This rich feature allows your calls to be directed with ease to voice messaging that allow you pick message without losing time and privacy.</p>
            </div>
          </div>

          <div class="col-lg-3 mt-4 mt-lg-0">
            <div
              class="box"
            >
              <img
                src="@/assets/img/features/redirect-to-any.svg"
                class="img-fluid mb-3"
                alt="redirect-to-any"
              >
              <h3>Redirect you phone to any number.</h3>
              <p>Some business requires to route the call to different numbers to manage different tasks.  Our interface is geared for easy routing and management for businesses</p>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="box"
            >
              <img
                src="@/assets/img/features/sms-ready.svg"
                class="img-fluid mb-3"
                alt="sms-ready"
              >
              <h3>SMS ready & Call recording</h3>
              <p>
                You need not have buy a new handset. With toto you
                can manage you new mobile or land number from same
                Mobile for Voice calls, SMS and Call recording.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Pricing />
    <section class="values">
      <div class="container">
        <header class="section-header">
          <p>totoconnect makes business agile!</p>
        </header>
        <p class="text-center text-gray">
          With our Cloud PBX and <b>totoconnect</b> you can make the office <br> <b>omnipresent</b> your audience <b>borderless</b>
        </p>
      </div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12 text-center">
            <a
              href=""
              class="mr-3"
            ><img
              src="@/assets/img/available-on-the-app-store.svg"
              style="height: 3rem;"
            ></a>
            <a href=""><img
              src="@/assets/img/get-it-on-google-play.svg"
              style="height: 3rem;"
            ></a>
          </div>
        </div>
      </div>
    </section>
    <Testimonials />
    <section class="features pt-0">
      <div class="container">
        <div class="row feature-icons">
          <div class="col-xl-6 d-flex content">
            <div class="col-xl-8 d-flex content">
              <div class="row align-self-center gy-4">
                <div class="col-md-12 icon-box mb-5">
                  <div class="icon">
                    <div class="easy-setup-icon" />
                  </div>
                  <div>
                    <h4>Easy to set up country <br>wise office numbers </h4>
                  </div>
                </div>
                <div class="col-md-12 icon-box mb-5">
                  <div class="icon">
                    <div class="multiple-profiles-icon" />
                  </div>
                  <div>
                    <h4>Multiple Numbers and <br>Multiple Profiles</h4>
                  </div>
                </div>
                <div class="col-md-12 icon-box mb-5">
                  <div class="icon">
                    <div class="free-calls-icon" />
                  </div>
                  <div>
                    <h4>Free Calls and <br>Messaging</h4>
                  </div>
                </div>
                <div class="col-md-12 icon-box mb-5">
                  <div class="icon">
                    <div class="cheap-intl-icon" />
                  </div>
                  <div>
                    <h4>Affordable and Cheap  <br>International Calls</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 text-center">
            <img
              src="@/assets/img/mobile-2.png"
              class="img-fluid"
              style="max-width:80%"
              alt="mobile-2"
            >
          </div>
        </div>
      </div>
    </section>
    <Contact />
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import Contact from '@/components/Contact.vue'
import Pricing from '@/components/Pricing.vue'
import Testimonials from '@/components/Testimonials.vue'

export default {
  name: 'Home',
  components: {
    MainHeader,
    MainFooter,
    Contact,
    Pricing,
    Testimonials,
  },
}
</script>
