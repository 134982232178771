<template>
  <footer
    class="footer"
  >
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 footer-links">
            <h4>Social Media</h4>
            <ul>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'FAQ' }"
                >
                  FAQ
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'About' }"
                >
                  About us
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'Terms' }"
                >
                  Terms and Conditions
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'Privacy' }"
                >
                  Privacy and Policy
                </b-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 footer-links">
            <h4>Social Media</h4>
            <ul>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'FAQ' }"
                >
                  FAQ
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'About' }"
                >
                  About us
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'Terms' }"
                >
                  Terms and Conditions
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'Privacy' }"
                >
                  Privacy and Policy
                </b-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 footer-links">
            <h4>Social Media</h4>
            <ul>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'FAQ' }"
                >
                  FAQ
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'About' }"
                >
                  About us
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'Terms' }"
                >
                  Terms and Conditions
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'Privacy' }"
                >
                  Privacy and Policy
                </b-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 footer-links">
            <h4>Social Media</h4>
            <ul>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'FAQ' }"
                >
                  FAQ
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'About' }"
                >
                  About us
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'Terms' }"
                >
                  Terms and Conditions
                </b-link>
              </li>
              <li>
                <b-icon icon="chevron-right" />
                <b-link
                  class="getstarted"
                  :to="{ name: 'Privacy' }"
                >
                  Privacy and Policy
                </b-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>Totoconnect.com</span></strong>. All Rights Reserved
      </div>
    </div>
    <!-- <b-link class="back-to-top d-flex align-items-center justify-content-center active">
      <i class="ri-arrow-up-line ri-xxs" />
    </b-link> -->
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
}
</script>
