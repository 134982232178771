import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import GetStart from '@/views/GetStart.vue'
import Login from '@/views/Login.vue'
import Terms from '@/views/Terms.vue'
import Privacy from '@/views/Privacy.vue'
import About from '@/views/About.vue'
import FAQ from '@/views/FAQ.vue'
import Plans from '@/views/Plans.vue'
import Checkout from '@/views/Checkout.vue'
import ContactUs from '@/views/ContactUs.vue'
import MyAccount from '@/views/MyAccount.vue'
import NotFound from '@/components/NotFound.vue'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: false,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      auth: false,
    },
  },
  {
    path: '/plans',
    name: 'Plans',
    component: Plans,
    meta: {
      auth: false,
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms',
    component: Terms,
    meta: {
      auth: false,
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      auth: false,
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    meta: {
      auth: false,
    },
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: {
      auth: true,
    },
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      auth: false,
    },
  },
  {
    path: '/get-start',
    name: 'GetStart',
    component: GetStart,
    meta: {
      guest: true,
      auth: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true,
      auth: false,
    },
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      auth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (store.getters.isSignedIn) {
      next()
    } else {
      next({ name: 'GetStart', query: { redirect: 'checkout' } })
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (!store.getters.isSignedIn) {
      next()
    }
    else {
      next({ name: 'MyAccount' })
    }
  } else {
    next()
  }
})

export default router
